import React from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

export default ({ title, actions, onChangeDropdownItem }) => {
  return (
    <UncontrolledDropdown className="w-100">
      <DropdownToggle className="w-100" style={{ height: '40px' }}>
        <span>{title}</span>
      </DropdownToggle>
      <DropdownMenu className="mb-2" left="true">
        {actions.map((item) => (
          <DropdownItem
            className="px-3 mb-0"
            key={item.name}
            onClick={() => onChangeDropdownItem(item)}
          >
            {item.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
