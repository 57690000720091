import React from 'react';
import supportOrange from 'assets/img/sidebar/support-orange.svg';
import useZendesk from 'hooks/useZendesk';

export default function Support() {
  const { handleOpenZendesk, hasUnreadMessages } = useZendesk();

  return (
    <div className="theme-colors">
      <a href="#section" className="theme-button" onClick={handleOpenZendesk}>
        {hasUnreadMessages && <div className="pulse" />}
        <img src={supportOrange} alt="suport-icon" />
      </a>
    </div>
  );
}
