/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { MdOutlineAdd } from 'react-icons/md';

import Button from 'components/Button';
import FormButtonList from 'components/FormButton/FormButtonList';
import educationIcon from 'assets/img/steps/education-icon.svg';
import FormTitle from 'components/FormTitle';

import AcademicEducationItem from './AcademicEducationItem';

export default function AcademicEducationList({
  data,
  isCompleted,
  onChangeEdit,
  onChangeRemove,
  onChangeNewFormation,
  onChangeNext,
  onChangePrev,
  isChangeResume = false,
}) {
  return (
    <div className="av-tooltip tooltip-label-bottom content-form">
      <div className="content-row">
        <FormTitle icon={educationIcon} title="Formação Acadêmica" />

        <div className="cards-list">
          {data.map((item, index) => (
            <AcademicEducationItem
              key={index}
              data={item}
              onChangeEdit={() => onChangeEdit(index)}
              onChangeRemove={() => onChangeRemove(index)}
            />
          ))}
        </div>
      </div>

      <div className="list-buttons">
        <Button
          label="Adicionar Formação"
          className={`add-mobile ${
            isChangeResume ? 'w-100 mr-2 mb-3' : 'w-100 my-3'
          }`}
          onClick={onChangeNewFormation}
          style={{ fontSize: isChangeResume && '0.8rem' }}
        />

        <div
          className="add-desktop d-flex align-items-center"
          role="button"
          tabIndex={0}
          onClick={onChangeNewFormation}
        >
          <span>Adicionar Formação</span>
          <span className="icon">
            <MdOutlineAdd size={24} fill="#fff" />
          </span>
        </div>

        <FormButtonList
          isChangeResume={isChangeResume}
          onChangeNext={onChangeNext}
          onChangePrev={onChangePrev}
          isCompleted={isCompleted}
        />
      </div>
    </div>
  );
}
