/* eslint-disable no-underscore-dangle */
import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import * as Sentry from '@sentry/react';

import colors from 'constants/colors';
import { ResumePreview, ResumeEdit } from 'containers/ResumePDFs';
import SuccessfulRegister from 'containers/SuccessfulRegister';

import Button from 'components/Button';
import ChooseResumeProperties from 'components/ChooseResumeProperties';
import SmallSide from 'components/Sidebar/SmallSide';
import { HeaderView } from 'components/Header';
import Modal from 'components/Modal';
import Bizut from 'components/PrivacyTerms/Bizut';
import Arteris from 'components/PrivacyTerms/Arteris';

import { getMonthFromString } from 'helpers/Utils';
import useLocalStorage from 'hooks/useLocalStorage';
import ResumeService from 'services/ResumeService';
import { HashContext } from 'contexts/HashContext';
import useAPICall from 'hooks/useAPICall';

import SelectPhoto from './SelectPhoto';
import './styles.scss';

const stepIsCompleted = (value) => {
  if (!value) {
    return false;
  }

  // Utilizado pra verificar todos os itens, menos o obj "complemento"
  const cloneInitialValues = (({ complement, ...values }) => values)(value);

  if (Object.values(cloneInitialValues).every((item) => item === '')) {
    return false;
  }

  if (Object.values(value).length === 0) {
    return false;
  }

  return true;
};

export default function ResumeView() {
  const history = useHistory();
  const componentRef = useRef();
  const downloadRender = useRef();
  const { decodedHash, isArteris, hash } = useContext(HashContext);
  const { handleAPICall } = useAPICall();

  const [personalData] = useLocalStorage('@step-one');
  const [localization] = useLocalStorage('@step-two');
  const [academicEducation] = useLocalStorage('@step-three');
  const [professionalExperience] = useLocalStorage('@step-four');
  const [storagedColor, setStoragedColor] = useLocalStorage('@color');
  const [storagedImage, setStoragedImage] = useLocalStorage('@image');
  const [termAccepted, setTermAccepted] = useLocalStorage(
    '@term-accepted',
    false
  );

  const [currentColorIndex, setCurrentColorIndex] = useState(
    storagedColor || 0
  );
  const [image, setImage] = useState(storagedImage || '');
  const [isPreview, setPreview] = useState(true);
  const [resumeIsSelected, setResumeIsSelected] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [termIsVisible, setTermVisible] = useState(false);
  const [isRegisteredModalVisibile, setRegisteredModal] = useState(false);
  const [previewsIsEnabled, setPreviewEnabled] = useState(false);

  const modalFooterRef = useRef(null);

  const reactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'curriculum',
  });

  useEffect(() => {
    console.log('chamou');

    const isValid =
      stepIsCompleted(personalData) &&
      stepIsCompleted(localization) &&
      stepIsCompleted(academicEducation);

    if (!isValid) {
      history.replace('/', null);
    } else {
      setPreviewEnabled(true);
    }
  }, [academicEducation, history, localization, personalData]);

  const experiences = useMemo(() => {
    return professionalExperience;
  }, [professionalExperience]);

  const educations = useMemo(() => {
    return academicEducation;
  }, [academicEducation]);

  const storageSteps = useMemo(
    () => [personalData, localization, educations, experiences],
    [educations, localization, personalData, experiences]
  );

  function handleChangeColor(colorIndex) {
    setCurrentColorIndex(colorIndex);
    setStoragedColor(colorIndex);
    // eslint-disable-next-line no-underscore-dangle
    window._loq?.push(['tag', 'Mudança de cor', true, true]);
  }

  function handleCurriculumImage(base64) {
    const imagebase64 = `data:image/*;base64,${base64}`;
    setImage(imagebase64);
    setStoragedImage(imagebase64);
    // eslint-disable-next-line no-underscore-dangle
    window._loq?.push(['tag', 'Imagem curriculo', true, true]);
  }

  const handlePrint = useCallback(() => {
    downloadRender.current = true;
    setPreview(false);

    if (!componentRef.current) {
      return;
    }

    reactPrint();
  }, [reactPrint]);

  function argsEnconded() {
    const splitedBornDate = personalData.bornDate.split('-');
    const urlencoded = new URLSearchParams();

    if (decodedHash.vaga_id !== null) {
      urlencoded.append(
        'data[CurriculosVaga][vaga_id_encode]',
        decodedHash.vaga_id
      );
    }

    urlencoded.append('data[CurrTmp][hash]', hash.split('$')[1]);
    urlencoded.append(
      'data[CurriculosEmpresa][empresa_id]',
      decodedHash.empresa_id
    );

    if (decodedHash.agregador_id !== null) {
      urlencoded.append('data[User][tracking]', decodedHash.agregador_id);
    }

    urlencoded.append('data[User][nome]', personalData.fullname);
    urlencoded.append('data[User][email]', personalData.email);
    urlencoded.append(
      'data[Curriculo][nacionalidade]',
      personalData.nacionality
    );
    urlencoded.append(
      'data[Curriculo][estado_civil]',
      personalData.maritalStatus.value
    );
    urlencoded.append(
      'data[Curriculo][data_nascimento][day]',
      splitedBornDate[2]
    );
    urlencoded.append(
      'data[Curriculo][data_nascimento][month]',
      splitedBornDate[1]
    );
    urlencoded.append(
      'data[Curriculo][data_nascimento][year]',
      splitedBornDate[0]
    );

    if (isArteris) {
      urlencoded.append(
        'data[Curriculo][cpf]',
        personalData.cpf.replaceAll('.', '').replace('-', '')
      );
    }

    urlencoded.append('data[Curriculo][sexo]', personalData.gender.value);
    urlencoded.append('data[Curriculo][pcd]', personalData.pcd.value);
    urlencoded.append(
      'data[Curriculo][pais_iso]',
      localization.country.Pais.id
    );
    urlencoded.append('data[Curriculo][estado]', localization.state.Estado.id);
    urlencoded.append(
      'data[Curriculo][cidade_id]',
      localization.city.Cidade.id
    );
    urlencoded.append('data[Curriculo][cep]', localization.cep);
    urlencoded.append('data[Curriculo][endereco]', localization.street);
    urlencoded.append('data[Curriculo][numero]', localization.number);
    urlencoded.append('data[Curriculo][complemento]', localization.complement);
    urlencoded.append('data[Curriculo][bairro]', localization.neighborhood);
    urlencoded.append('data[Curriculo][num_celular]', personalData.phone);

    academicEducation.forEach((item, index) => {
      urlencoded.append(
        `data[Curriculo][Formacao][${index + 1}][instituicao_nome]`,
        item.institutionName.Instituicao.nome
      );

      urlencoded.append(
        `data[Curriculo][Formacao][${index + 1}][nivel]`,
        item.formationLevel.value
      );
      urlencoded.append(
        `data[Curriculo][Formacao][${index + 1}][situacao]`,
        item.currentSituation.value
      );
      urlencoded.append(
        `data[Curriculo][Formacao][${index + 1}][ano_inicio]`,
        item.startYear.value
      );
      urlencoded.append(
        `data[Curriculo][Formacao][${index + 1}][ano_conclusao]`,
        item.conclusionYearEducation.value
      );

      if (item.formationLevel.value === 4 || item.formationLevel.value === 5) {
        return;
      }

      urlencoded.append(
        `data[Curriculo][Formacao][${index + 1}][curso_nome]`,
        item.courseName.Curso.id
      );

      urlencoded.append(
        `data[Curriculo][Formacao][${index + 1}][curso_nome]`,
        item.courseName.Curso.nome
      );

      if (item.courseName.Curso.id === 999) {
        urlencoded.append(
          `data[Curriculo][Formacao][${index + 1}][curso_id]`,
          item.courseName.Curso.id
        );
      }

      if (item.institutionName.Instituicao.id === 999) {
        urlencoded.append(
          `data[Curriculo][Formacao][${index + 1}][instituicao_id]`,
          item.institutionName.Instituicao.id
        );
      }
    });

    professionalExperience.forEach((item, index) => {
      urlencoded.append(
        `data[Curriculo][Experiencia][${index}][empresa]`,
        item.company
      );
      urlencoded.append(
        `data[Curriculo][Experiencia][${index}][cargo]`,
        item.companyPosition
      );
      urlencoded.append(
        `data[Curriculo][Experiencia][${index}][cargo_id]`,
        item.companyPosition.value
      );
      urlencoded.append(
        `data[Curriculo][Experiencia][${index}][data_entrada]`,
        `${item.startYear.value}-${getMonthFromString(
          item.startMonth.value
        )}-01`
      );

      if (!item.currentJob) {
        urlencoded.append(
          `data[Curriculo][Experiencia][${index}][data_saida]`,
          `${item.conclusionYearExperience.value}-${getMonthFromString(
            item.conclusionMonth.value
          )}-01`
        );
      } else {
        urlencoded.append(
          `data[Curriculo][Experiencia][${index}][data_saida]`,
          ''
        );
      }

      urlencoded.append(
        `data[Curriculo][Experiencia][${index}][texto]`,
        item.description
      );
    });

    return urlencoded;
  }

  async function handleSaveResume() {
    try {
      setLoadingButton(true);

      const args = argsEnconded();
      const response = await handleAPICall(() =>
        ResumeService.registerResume(args)
      );

      if (response.status === 'success') {
        setRegisteredModal(true);
        window._loq?.push(['tag', 'Curriculo salvo', true, true]);
      } else {
        Sentry.captureMessage(
          `SENTRY_SAVE_RESUME::ERROR ${JSON.stringify(response)}`
        );
        window._loq?.push(['tag', 'Cadastro com Erro', true, true]);
      }
    } catch (error) {
      Sentry.captureMessage(
        `SENTRY_SAVE_RESUME::ERROR ${JSON.stringify(error)}`
      );
      console.error('SAVE_RESUME::ERROR', error);
      // eslint-disable-next-line no-underscore-dangle
      window._loq?.push(['tag', 'Cadastro com Erro', true, true]);
    } finally {
      setLoadingButton(false);
    }
  }

  function handleCloseModal() {
    setRegisteredModal(false);
    setResumeIsSelected(true);
    localStorage.clear('@step-one');
    localStorage.clear('@step-two');
    localStorage.clear('@step-three');
    localStorage.clear('@step-four');
    localStorage.clear('@step-three-completed');
    localStorage.clear('@swiperIndex');
    localStorage.clear('@color');
    localStorage.clear('@image');
    localStorage.clear('@need-help');
    localStorage.clear('@term-accepted');
    localStorage.clear('lo_session');
    localStorage.clear('@startedModal');
  }

  const onScroll = () => {
    modalFooterRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleAcceptTerm = useCallback(
    (isChecked) => {
      if (!isChecked) {
        return;
      }

      setTermAccepted(isChecked);
      // eslint-disable-next-line no-underscore-dangle
      window._loq?.push(['tag', 'Termo aceito', true, true]);
      setTermVisible(false);
    },
    [setTermAccepted]
  );

  useEffect(() => {
    if (downloadRender.current === false) {
      return;
    }

    if (!isPreview) {
      handlePrint();
    }

    setTimeout(() => {
      setPreview(true);
    }, 1);
  }, [handlePrint, isPreview, reactPrint]);

  return (
    <>
      <Modal isVisible={termIsVisible}>
        {isArteris ? (
          <Arteris
            onAcceptTerm={(isChecked) => handleAcceptTerm(isChecked)}
            footerRef={modalFooterRef}
            onScroll={onScroll}
          />
        ) : (
          <Bizut
            mainPrivacyTerm
            footerRef={modalFooterRef}
            onAcceptTerm={(isChecked) => handleAcceptTerm(isChecked)}
            onScroll={onScroll}
          />
        )}
      </Modal>

      <Modal isVisible={isRegisteredModalVisibile}>
        <SuccessfulRegister
          onCloseModal={handleCloseModal}
          isJobOpportunity={decodedHash.vaga_id !== null}
        />
      </Modal>

      {previewsIsEnabled && (
        <div className="resume-view">
          <SmallSide supportIsVisible />

          <div className="resume-view-main">
            <HeaderView />

            <div className="resume-view-content">
              <div
                className={`resume-view-resumes ${
                  resumeIsSelected && 'resume-selected mt-0'
                }`}
              >
                <h3 className={`desktop ${resumeIsSelected && 'd-none'}`}>
                  Selecione o modelo de currículo que você deseja!
                </h3>
                <ResumePreview
                  ref={componentRef}
                  image={image}
                  currentColorIndex={currentColorIndex}
                  colors={colors}
                  storageSteps={storageSteps}
                  isPreview={isPreview}
                  resumeIsSelected={resumeIsSelected}
                />
              </div>

              <div className="divisor-resume-view desktop" />

              <div className={`footer ${resumeIsSelected && 'mb-5'}`}>
                {resumeIsSelected ? (
                  <div className="right-side">
                    <div className="text">
                      <h3>Agradecemos pelo seu cadastro!</h3>
                      <p>
                        O Bizut está à disposição para o que você precisar e
                        informamos também que uma senha será enviada para o seu
                        email.
                      </p>
                    </div>
                    <div className="d-flex mx-4 resume-buttons">
                      <Button
                        className="w-100"
                        label="Download"
                        onClick={handlePrint}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <h3 className="text-center desktop">
                      Parabéns, seu currículo esta quase pronto!
                    </h3>
                    <SelectPhoto
                      onChangeInputFile={(base64) =>
                        handleCurriculumImage(base64)
                      }
                      image={image}
                    />
                    <ChooseResumeProperties
                      colors={colors}
                      currentColorIndex={currentColorIndex}
                      onChangeInputFile={(base64) =>
                        handleCurriculumImage(base64)
                      }
                      onChangeColor={(colorIndex) =>
                        handleChangeColor(colorIndex)
                      }
                    />
                    <div className="d-flex mx-4 resume-buttons">
                      <ResumeEdit />
                      <Button
                        isLoading={loadingButton}
                        className="w-100 ml-2"
                        label="Finalizar"
                        onClick={() =>
                          termAccepted
                            ? handleSaveResume()
                            : setTermVisible(true)
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
