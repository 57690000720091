/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import person from 'assets/img/pdf/person.png';
import upload from 'assets/img/pdf/upload.png';
import './styles.scss';

export default function SelectPhoto({ onChangeInputFile, image }) {
  const inputRef = useRef(null);

  function fileTypeIsValid(fileType) {
    if (
      fileType === 'image/png' ||
      fileType === 'image/jpeg' ||
      fileType === 'image/jpg'
    ) {
      return true;
    }
    return false;
  }

  function handleInputImage(e) {
    const file = e.target.files[0];
    if (fileTypeIsValid(file?.type) && file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '');
        onChangeInputFile(base64String);
        // Logs wL2dvYWwgbW9yZ...
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <div className="select-photo desktop d-flex flex-column">
      <div onClick={() => inputRef.current.click()} role="button" tabIndex={0}>
        <input
          ref={inputRef}
          type="file"
          className="d-none"
          onChange={handleInputImage}
        />
        <img src={image || person} alt="icon" className="avatar" />
        <img src={upload} alt="icon" className="upload" />
      </div>
      <h4>Escolha sua foto</h4>
    </div>
  );
}
