import Button from 'components/Button';
import React from 'react';

import Bizut from './Bizut';
import './styles.scss';

export default function Arteris({ onAcceptTerm, onScroll, footerRef }) {
  return (
    <div className="privacy-container">
      <div className="privacy__logo">
        <img
          src="http://bizut.me/img/logo_arteris_fundo_claro.png"
          width="150px"
          alt="logo"
        />
      </div>

      <h1 className="text-center mb-3">POLÍTICA DE PRIVACIDADE ARTERIS</h1>
      <div
        style={{
          width: '100%',
          margin: '0.8rem 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={onScroll}
          label={
            <>
              <span className="mr-1">Ir para o final</span>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 2.91659L7 11.0833"
                  stroke="white"
                  strokeWidth="1.16667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.0833 6.99992L7 11.0833L2.91666 6.99992"
                  stroke="white"
                  strokeWidth="1.16667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          }
          style={{
            borderRadius: '.4rem',
          }}
        />
      </div>
      <p>Atualização: 19 de fevereiro de 2020</p>
      <p>
        Esta Política de Privacidade (“Política”) é o documento que demonstra o
        compromisso da
      </p>

      <div className="privacy__box">
        <b>ARTERIS S.A. e as suas empresas Controladas</b>
        {`(em conjunto 
            "Empresas Arteris" ou "Arteris" / "nós" / "nosso(a)")`}
        <br />
        <b>CNPJ:</b> 02.919.555/0001-67
        <br />
        <b>Endereço:</b> Av. Pres. Juscelino Kubitschek, 510, 12º Andar, Vila
        Nova Conceição, São Paulo, SP, Brasil, CEP 04543-906
      </div>

      <p>
        com a privacidade e proteção das suas informações, quando tratamos seus
        Dados Pessoais ao prestar regularmente nossos serviços e quando você
        utiliza nossos sites.
      </p>
      <p>
        Nesta Política, descrevemos como os seus Dados Pessoais são coletados,
        usados, compartilhados e armazenados.
      </p>
      <p>
        Esta Política de Privacidade se aplica às concessionárias da Arteris:
        ViaPaulista, Intervias, Litoral Sul, Régis Bittencourt, Fernão Dias,
        Fluminense, Planalto Sul.
      </p>

      <div className="privacy__box">
        <h4>
          NOTA ESPECIAL PARA CRIANÇAS E ADOLESCENTES COM IDADE INFERIOR A 18
          ANOS
        </h4>

        <p>
          <u>
            Por favor, não utilize os serviços dos nossos sites caso você possua
            menos de 18 anos.
          </u>
        </p>

        <h4>NOTA ESPECIAL PARA OS REPRESENTANTES LEGAIS</h4>
        <p>
          Os pais/representantes legais devem sempre supervisionar as atividades
          online dos seus filhos menores de idade.
          <br />
          As atividades de adolescentes menores de 18 anos devem ser assistidas
          pelos pais ou representantes legais.
        </p>
      </div>

      <h2>DADOS PESSOAIS QUE COLETAMOS</h2>
      <p>
        Os dados pessoais que nós coletamos dependem de como e por que você se
        relaciona conosco. Nós podemos coletar os seguintes Dados Pessoais:
      </p>
      <p>
        <u>Dados Pessoais que você nos fornece voluntariamente</u>
      </p>
      <ul>
        <li>
          Nome completo, CPF, data de nascimento, origem racial, gênero,
          endereço, CEP, telefone, e-mail, placa do veículo, senha, conteúdo da
          sua mensagem e informações sobre a ocorrência que você nos relatar.
        </li>
        <li>
          Nós também podemos receber os Dados Pessoais que você decidir
          compartilhar conosco, como o seu histórico profissional, por exemplo.
        </li>
      </ul>
      <p>
        <u>Dados Pessoais que coletamos automaticamente</u>
      </p>
      <ul>
        <li>
          Imagens das câmeras de segurança e monitoramento das praças de pedágio
          e rodovias, endereço IP, informações sobre o dispositivo de acesso (p.
          ex. modelo, fabricante, sistema operacional), informações sobre o
          navegador de internet, duração da visita, páginas visitadas, conteúdos
          que você interagiu e Cookies.
        </li>
      </ul>
      <h2>PARA QUE COLETAMOS OS DADOS PESSOAIS</h2>
      <p>
        Nós utilizamos seus Dados Pessoais para diversas finalidades em nossos
        serviços:
      </p>
      <ul>
        <li>
          Apurar denúncias realizadas em nosso Canal de Denúncias e tomar as
          providências necessárias.
        </li>
        <li>Corrigir eventuais problemas técnicos em nossos sites.</li>
        <li>
          Cumprir as nossas obrigações decorrentes do uso dos nossos serviços.
        </li>
        <li>Cumprir as nossas obrigações legais e regulatórias.</li>
        <li>Enviar comunicação por e-mail, telefone ou mensagens de texto.</li>
        <li>
          Implementar adequadas medidas adequadas de segurança para resguardar
          você e a Arteris.
        </li>
        <li>Obter informações sobre o uso de nossos serviços.</li>
        <li>
          Permitir a nossa comunicação com você, atender às suas solicitações,
          responder as suas dúvidas e reclamações.
        </li>
        <li>
          Permitir que você emita o Documento Fiscal Equivalente (DFE) das suas
          passagens em nossas praças de pedágio.
        </li>
        <li>
          Processar sua candidatura a uma oportunidade de trabalho conosco.
        </li>
        <li>
          Proteger você e a Arteris contra fraudes e quaisquer outros atos
          ilícitos, incluindo a troca de informações com órgãos públicos, outras
          empresas e organizações para fins investigativos e informativos.
        </li>
      </ul>

      <p>
        O serviço de emissão do Documento Fiscal Equivalente (DFE) depende
        diretamente do fornecimento de Dados Pessoais, principalmente os
        cadastrais (p. ex. Nome, CPF, placa do veículo). Caso você opte por não
        fornecer esses Dados Pessoais de cadastro, podemos ficar
        impossibilitados de prestar o serviço a você.
      </p>
      <p>
        Nós não somos obrigados a tratar quaisquer dos seus Dados Pessoais se
        houver razões para crer que tal tratamento possa nos imputar qualquer
        infração de qualquer lei aplicável, ou se você estiver utilizando nossos
        sites para quaisquer fins ilegais ou ilícitos.
      </p>
      <p>Nós utilizamos, em nossas Plataformas, as seguintes tecnologias:</p>
      <ul>
        <li>
          Cookies: Você pode saber mais sobre como utilizamos cookies no tópico
          abaixo.
        </li>
        <li>
          Google Analytics, para registro de dados de navegação não
          identificáveis para análise.
        </li>
      </ul>
      <p>
        Todas as tecnologias utilizadas respeitam a legislação vigente e os
        termos desta Política.
      </p>

      <div className="sessao arteris">
        <div className="sessaoInner">
          <h2>COMO UTILIZAMOS OS COOKIES</h2>
          <p>
            <i>Cookies</i> são pequenos arquivos digitais em formato de texto
            que são armazenados no seu dispositivo (computador, smartphone,
            tablet etc.) pelo navegador de internet e que guardam informações
            relacionadas às suas preferências, como idioma preferido,
            localização, recorrência das suas sessões e outras variáveis para
            tornar a sua experiência muito mais eficiente.
          </p>
          <p>
            Os <i>Cookies</i> servem para aprimorar a sua experiência, tanto em
            termos de performance, como em termos de usabilidade, uma vez que os
            conteúdos disponibilizados serão direcionados às suas necessidades e
            expectativas.
          </p>
          <p>
            Eles também podem ser utilizados para realizar{' '}
            <u>estatísticas anônimas</u> e agregadas que permitem entender como
            o usuário utiliza o site, bem como para aprimorar suas estruturas e
            conteúdo.{' '}
            <u>
              Por serem estatísticas anônimas, não é possível identificá-lo
              pessoalmente por meio desses dados.
            </u>
          </p>
          <ul>
            <li>
              <b>Cookies Necessários:</b> Permitem a navegação e utilização das
              aplicações, bem como acessar a áreas seguras do site. Sem estes
              Cookies, o site não funciona corretamente.
            </li>
            <li>
              <b>Cookies Analíticos:</b> Coletam dados estatísticos anônimos com
              a finalidade de analisar a utilização do site e seu respectivo
              desempenho.
            </li>
            <li>
              <b>Cookies de Funcionalidade:</b> São utilizados para assegurar a
              disponibilização de funcionalidades adicionais do site ou para
              guardar as preferências definidas pelo usuário ao navegar na
              internet, sempre que utilizar o mesmo dispositivo.
            </li>
          </ul>
          <h4>
            <u>Em nossos sites utilizamos os seguintes Cookies:</u>
          </h4>
          <table border="1" width="100%">
            <tbody>
              <tr>
                <th>Site Arteris</th>
                <th>Nome do Cookie</th>
                <th>Categoria do Cookie</th>
                <th>Função do Cookie</th>
                <th>Validade</th>
              </tr>
              <tr>
                <td>arteris.com.br</td>
                <td>_ga</td>
                <td>Analítico</td>
                <td>Identificação do acesso ao site (Google Analytics)</td>
                <td>2 anos</td>
              </tr>
              <tr>
                <td>arteris.com.br</td>
                <td>_gid</td>
                <td>Analítico</td>
                <td>Identificar a atividade no site (Google Analytics)</td>
                <td>1 dia</td>
              </tr>
              <tr>
                <td>ri.arteris.com.br</td>
                <td>Session</td>
                <td>Funcionalidade</td>
                <td>
                  Permanecer com informações durante a navegação do usuário
                </td>
                <td>fim da sessão</td>
              </tr>
              <tr>
                <td>dfe.arteris.com.br</td>
                <td>ASP.NET_SessionId</td>
                <td>Necessário</td>
                <td>Identificar o usuário por parte do Identity</td>
                <td>fim da sessão</td>
              </tr>
              <tr>
                <td>canalconfidencial.com.br/arteris/</td>
                <td>_ga</td>
                <td>Analítico</td>
                <td>Identificação do acesso ao site (Google Analytics)</td>
                <td>2 anos</td>
              </tr>
              <tr>
                <td>canalconfidencial.com.br/arteris/</td>
                <td>_gid</td>
                <td>Analítico</td>
                <td>Identificar a atividade no site (Google Analytics)</td>
                <td>1 dia</td>
              </tr>
              <tr>
                <td>canalconfidencial.com.br/arteris/</td>
                <td>_gat</td>
                <td>Analítico</td>
                <td>Controlar a taxa de solicitação (Google Analytics)</td>
                <td>1 minuto</td>
              </tr>
            </tbody>
          </table>
          <p>
            Você pode alterar as configurações para bloquear o uso de cookies ou
            alertá-lo quando um cookie estiver sendo enviado para seu
            dispositivo. Consulte as instruções ou a seção de ajuda do seu
            navegador para saber mais sobre como ajustar ou alterar essas
            configurações.
          </p>
        </div>
      </div>
      <div className="sessao controladoras">
        <div className="sessaoInner">
          <h2>COMO COMPARTILHAMOS OS DADOS PESSOAIS</h2>
          <p>
            Nós podemos compartilhar seus Dados Pessoais com terceiros, sempre
            buscando preservar ao máximo a sua privacidade e, sempre que
            possível, de forma anonimizada.
          </p>
          <p>
            Abaixo, descrevemos situações em que podemos compartilhar os seus
            Dados Pessoais:
          </p>
          <ul>
            <li>
              Internamente, com nossas áreas de negócio, para promover e
              desenvolver nossos serviços, por exemplo.
            </li>
            <li>
              Com nossos fornecedores e parceiros comerciais, com quem firmamos
              obrigações contratuais de segurança e proteção de dados pessoais.
              Os fornecedores incluem empresas de hospedagem de dados e
              servidores; empresas de segurança, como a gestão do canal de
              denúncias; empresas de cobrança automática em pedágios.
            </li>
            <li>
              Com Autoridades Públicas, sempre que houver determinação legal,
              requerimento, requisição ou ordem judicial nesse sentido.
            </li>
            <li>
              De forma automática, em caso de movimentações societárias, como
              fusão, aquisição e incorporação da Arteris.
            </li>
          </ul>
          <p>
            Para pesquisas, divulgação de dados à imprensa e realização de
            campanhas institucionais, os Dados Pessoais serão compartilhados de
            forma anonimizada.
          </p>
          <div className="privacy__box">
            <p>
              Os Dados Pessoais podem ser{' '}
              <u>transferidos para fora do Brasil</u>. Essa transferência ocorre
              por conta de alguns dos nossos fornecedores, que podem estar
              localizados no exterior. Nesses casos, tomamos o cuidado de
              selecionar apenas empresas que demonstrem estar em conformidade
              com as leis aplicáveis.
            </p>
          </div>
          <br />
          <h2>SEUS DIREITOS E COMO EXERCÊ-LOS</h2>
          <p>
            A LGPD garante a você, como Titular dos Dados Pessoais, os seguintes
            direitos:
          </p>
          <ul>
            <li>
              Acesso: Você requisitar uma cópia dos Dados Pessoais que nós temos
              sobre você.
            </li>
            <li>
              Anonimização, bloqueio ou eliminação: Você pode solicitar a
              anonimização de seus Dados Pessoais; o bloqueio de seus Dados
              Pessoais, suspendendo temporariamente o Tratamento e eliminação de
              seus Dados Pessoais, caso em que apagaremos todos os seus Dados
              Pessoais, salvo os casos em que a lei exigir sua manutenção.
            </li>
            <li>
              Confirmação de Tratamento: Você pode verificar se a Arteris trata
              algum Dado Pessoal seu.
            </li>
            <li>
              Correção: Você solicitar a correção dos Dados Pessoais que estejam
              incompletos, inexatos ou desatualizados.
            </li>
            <li>
              Informações sobre o compartilhamento: Você tem o direito de saber
              com quais entidades públicas e privadas a Arteris compartilha os
              seus Dados Pessoais.
            </li>
            <li>
              Oposição: Você pode se opor às atividades de Tratamento que não
              dependam do consentimento. Caso você não concorde com o referido
              Tratamento, você poderá solicitar sua interrupção.
            </li>
            <li>
              Revogação do consentimento e informação sobre suas consequências:
              Você tem o direito de não dar ou retirar seu consentimento e obter
              informações sobre as consequências dessa escolha. Nesse caso,
              alguns de nossos serviços poderão ficar indisponíveis para você
              (por exemplo, a emissão do DFE).
            </li>
          </ul>
          <p>
            Você poderá entrar em contato com nosso Encarregado para exercer os
            direitos acima descritos.
          </p>
        </div>
      </div>

      <h2>COMO ARMAZENAMOS OS DADOS PESSOAIS</h2>
      <p>
        Os Dados Pessoais são armazenados somente pelo tempo que for necessário
        para cumprir com as finalidades para as quais foram coletados, salvo se
        houver outra razão para sua manutenção como o cumprimento de obrigações
        legais, regulatórias, contratuais e preservação de direitos da Arteris,
        desde que devidamente fundamentadas.
      </p>
      <p>
        Caso você solicite a exclusão de seus Dados Pessoais, pode ocorrer que
        eles precisem ser mantidos por período superior ao pedido de exclusão,
        nos termos do artigo 16 da LGPD.
      </p>
      <p>
        Terminado o prazo de manutenção e necessidade legal, os Dados Pessoais
        serão excluídos com uso de métodos de descarte seguro ou utilizados de
        forma anônima para fins estatísticos.
      </p>
      <h2>CONFIDENCIALIDADE E SEGURANÇA</h2>
      <p>
        Internamente, os Dados Pessoais são acessados somente por profissionais
        devidamente autorizados, respeitando os princípios de proporcionalidade,
        necessidade e relevância, além do compromisso de confidencialidade e
        preservação da sua privacidade.{' '}
      </p>
      <p>
        Trabalhamos com padrões elevados de privacidade e segurança em todos os
        nossos processos, garantindo que seus Dados Pessoais não sejam vendidos
        ou divulgados ilegalmente a terceiros.
      </p>
      <p>
        Ao utilizar nossos sites, você poderá ser conduzido, via link, a sites
        de terceiros, que poderão coletar suas informações e ter sua própria
        Política de Privacidade.
      </p>
      <p>
        Nós recomendamos que você leia as Políticas de Privacidade de tais
        sites, sendo de sua responsabilidade aceitá-las ou rejeitá-las. Nós não
        somos responsáveis pelas Políticas de Privacidade de terceiros e nem
        pelo conteúdo de quaisquer sites ou serviços que não os nossos.
      </p>
      <p>
        A Arteris nunca envia e-mails solicitando downloads ou execução de ações
        (abertura de arquivos com extensão .exe, .com, .src, .bat, entre
        outras).
      </p>
      <h2>DISPOSIÇÕES GERAIS</h2>
      <p>
        Estamos constantemente trabalhando para aperfeiçoar nossos serviços,
        conteúdo e navegação.
      </p>
      <p>
        Por esse motivo, a nossa Política pode passar por atualizações para
        refletir as melhorias realizadas. Dessa forma, recomendamos a visita
        periódica desta página para que você tenha conhecimento sobre as
        modificações efetuadas.
      </p>
      <p>
        Em caso de solicitações, reclamações, dúvidas ou sugestões em relação a
        nossa Política, por favor entre em contato com nosso Encarregado:
        lgpd@arteris.com.br
      </p>
      <p>
        Esta Política será interpretada segundo a legislação brasileira, no
        idioma português, sendo eleito o foro do seu domicílio para dirimir
        qualquer controvérsia que envolva este documento, salvo ressalva
        específica de competência pessoal, territorial ou funcional pela
        legislação aplicável.
      </p>

      <h2>GLOSSÁRIO</h2>
      <ul>
        <li>
          <b>Anonimização:</b> Utilização de meios técnicos razoáveis e
          disponíveis no momento do Tratamento, por meio dos quais um dado perde
          a possibilidade de associação, direta ou indireta, a um indivíduo.
        </li>
        <li>
          <b>
            <u>Cookies:</u>
          </b>{' '}
          Pequenos arquivos enviados pelos websites, salvos nos seus
          dispositivos, que armazenam as preferências e poucas outras
          informações, com a finalidade de personalizar sua navegação de acordo
          com o seu perfil.{' '}
        </li>
        <li>
          <b>Dado Pessoal:</b> É toda informação relacionada a uma pessoa
          natural identificada ou identificável.
        </li>
        <li>
          <b>Documento Fiscal Equivalente (“DFE”):</b> é um documento fiscal
          equivalente complementar ao recibo de pedágio, constando informações
          adicionais como o CPF ou CNPJ e a placa do veículo, e pode ser obtido
          via website disponibilizado pelas concessionárias Arteris aos usuários
          de suas rodovias. A emissão do DFE Complementar fica disponível até 07
          (sete) dias após o pagamento do pedágio em dinheiro ou por sistemas de
          cobrança automática. O DFE contém todas as informações das
          características da transação: emissor, CNPJ, data, local e horário de
          emissão, valor pago e descrição do serviço prestado, sendo válido para
          comprovação de despesas.
        </li>
        <li>
          <b>Encarregado (Data Protection Officer -DPO):</b> Pessoa indicada por
          nós para atuar como canal de comunicação entre a Arteris, você e a
          Autoridade Nacional de Proteção de Dados (ANPD).
        </li>
        <li>
          <b>Endereço IP:</b> Abreviatura de Internet Protocol. É o conjunto
          alfanumérico que identifica os dispositivos dos usuários na Internet.
        </li>
        <li>
          <b>LGPD: </b>Lei Geral de Proteção de Dados Pessoais – Lei nº.
          13.709/18, que dispõe sobre o tratamento de dados pessoais em meios
          digitais ou não.
        </li>
        <li>
          <b>Titular dos Dados Pessoais:</b> É você, a pessoa física a quem os
          Dados Pessoais se referem.
        </li>
        <li>
          <b>Tratamento:</b> Toda operação realizada com Dados Pessoais, como a
          coleta, produção, recepção, classificação, utilização, acesso,
          reprodução, transmissão, distribuição, processamento, arquivamento,
          armazenamento, eliminação, avaliação ou controle da informação,
          modificação, comunicação, transferência, difusão ou extração.
        </li>
      </ul>

      <h2>CONTATO</h2>
      <p>
        A Arteris e suas controladas possuem o seguinte Canal para
        Comunicação(ões) e Solicitações a Encarregada de Proteção de Dados
        Pessoais: lgpd@arteris.com.br
      </p>

      <Bizut
        mainPrivacyTerm={false}
        onAcceptTerm={(isChecked) => onAcceptTerm(isChecked)}
        footerRef={footerRef}
      />
    </div>
  );
}
