import HttpClient from 'services/utils/HttpClient';
import { BASE_URL } from 'services/utils/URL';

class HashService {
  httpClient;

  constructor() {
    this.httpClient = new HttpClient(BASE_URL);
  }

  decodeHash(hash) {
    return this.httpClient.get(`/endpointcurriculos/acessar/${hash}`);
  }
}

export default new HashService();
