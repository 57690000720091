/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useCallback } from 'react';
import cameraIcon from 'assets/img/pdf/camera-pdf.svg';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';

export default function ChooseResumeColor({
  colors,
  onChangeColor,
  currentColorIndex,
  onChangeInputFile,
}) {
  const inputRef = useRef(null);
  const colorsRef = useRef(null);

  function fileTypeIsValid(fileType) {
    if (
      fileType === 'image/png' ||
      fileType === 'image/jpeg' ||
      fileType === 'image/jpg'
    ) {
      return true;
    }
    return false;
  }

  function handleInputImage(e) {
    const file = e.target.files[0];

    if (fileTypeIsValid(file?.type) && file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '');
        onChangeInputFile(base64String);
        // Logs wL2dvYWwgbW9yZ...
      };
      reader.readAsDataURL(file);
    }
  }

  const handleScrollLeft = useCallback(() => {
    colorsRef.current.scrollBy({
      left: -300,
      behavior: 'smooth',
    });
  }, []);

  const handleScrollRigth = useCallback(() => {
    colorsRef.current.scrollBy({
      left: 300,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="resume-properties">
      <div className="content">
        <div className="photo" onClick={() => inputRef.current.click()}>
          <p>Foto</p>
          <div>
            <input
              ref={inputRef}
              type="file"
              className="d-none"
              onChange={handleInputImage}
            />
            <img src={cameraIcon} alt="camera" />
          </div>
        </div>

        <div className="divisor mobile" />

        <div className="colors" ref={colorsRef}>
          <p>Cor</p>
          <div className="scroll-colors">
            <MdKeyboardArrowLeft
              size={20}
              className="arrow-left desktop"
              onClick={handleScrollLeft}
            />

            {colors?.map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                ref={colorsRef}
                role="button"
                onClick={() => onChangeColor(index)}
                onKeyDown={() => onChangeColor(index)}
                tabIndex={0}
                style={{
                  background: colors[index],
                  border:
                    currentColorIndex === index
                      ? '2px solid #222'
                      : `2px solid ${colors[index]}`,
                }}
              />
            ))}
            <MdKeyboardArrowRight
              size={20}
              className="arrow-right desktop"
              onClick={handleScrollRigth}
            />
          </div>
        </div>
      </div>

      <h4>Escolha a cor do currículo</h4>
    </div>
  );
}
