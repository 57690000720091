import HttpClient from 'services/utils/HttpClient';
import { BASE_URL } from 'services/utils/URL';

class AcademicEducationService {
  httpClient;

  constructor() {
    this.httpClient = new HttpClient(BASE_URL);
  }

  findInsitutionsByName(name) {
    return this.httpClient.get(`/endpointcurriculos/instituicoes/${name}`);
  }

  findCoursesByName(name) {
    return this.httpClient.get(`/endpointcurriculos/cursos/${name}`);
  }
}

export default new AcademicEducationService();
