import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { configureStore } from './redux/store';
import reportWebVitals from './reportWebVitals';
import App from './App';

Sentry.init({
  dsn: 'https://d3db3c8b0a964039b70705b8a2caf567@o1138778.ingest.sentry.io/6541914',
  integrations: [
    new BrowserTracing(),
    new CaptureConsoleIntegration({ levels: ['error'] }),
  ],
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === 'production',

  tracesSampleRate: 1.0,
});

const Main = () => {
  return (
    <Provider store={configureStore()}>
      <App />
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
