import React, { useCallback, useContext } from 'react';
import { HashContext } from 'contexts/HashContext';
import { useHistory } from 'react-router-dom';

import BottomNavigation from 'components/wizard/BottomNavigation';
import Button from 'components/Button';

export default function FormButton({
  isChangeResume,
  isCompleted,
  prevLabel = 'Voltar',
  nextLabel = 'Próximo',
  onChangeNext,
  onChangePrev,
}) {
  const { hash } = useContext(HashContext);
  const history = useHistory();

  const handleNavigateToResume = useCallback(() => {
    history.replace(`/visualizar/${hash}`);
  }, [hash, history]);

  return (
    <>
      {isChangeResume ? (
        <div className="d-flex w-100 form-buttons">
          <Button
            label="Cancelar"
            color="secondary"
            style={{ fontSize: isChangeResume && '0.8rem' }}
            className="w-100 mr-2"
            onClick={handleNavigateToResume}
          />
          <Button
            label="Salvar"
            className="w-100"
            style={{ fontSize: isChangeResume && '0.8rem' }}
            onClick={handleNavigateToResume}
          />
        </div>
      ) : (
        <BottomNavigation
          isCompleted={isCompleted}
          onClickNext={onChangeNext}
          onClickPrev={onChangePrev}
          className="justify-content-center"
          prevLabel={prevLabel}
          nextLabel={nextLabel}
        />
      )}
    </>
  );
}
