/* eslint-disable no-underscore-dangle */
import React from 'react';

import useLocalStorage from 'hooks/useLocalStorage';
import Modal from 'components/Modal';
import Button from 'components/Button';
import './styles.modal.scss';

export default function PersonalModal() {
  const [storage, setStorageValue] = useLocalStorage('@startedModal', true);

  React.useEffect(() => {
    window._loq?.push(['tag', 'Modal Boas Vindas', true, true]);
  }, []);

  function handleCloseModal() {
    setStorageValue(false);
    window._loq?.push(['tag', 'Dados Pessoais', true, true]);
  }

  return (
    <Modal isVisible={storage}>
      <div className="content-modal">
        <h1>Seja bem-vindo!</h1>
        <p>
          Chegou a hora de cadastrar o seu currículo e criamos uma forma simples
          e rápida para que você possa informar tudo sobre a sua vida
          profissional.
        </p>
        <p>
          Essa é a melhor maneira de cadastrar, a preferida das empresas, e por
          isso não é possível importar o seu currículo de nenhum arquivo.
        </p>
        <p>
          Quando você acabar, poderá personalizar as cores e formato do seu
          currículo e ainda fazer o download para guardá-lo ou enviá-lo para
          quem quiser.
        </p>
        <p>Mas não se preocupe, tudo isso leva em média 10 minutos!</p>
        <Button label="Fechar" onClick={handleCloseModal} />
      </div>
    </Modal>
  );
}
