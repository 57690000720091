import React from 'react';

import logo from 'assets/logos/logo_bizut.png';
import SmallSide from './SmallSide';
import './styles.scss';

export default function Sidebar({
  title,
  observation,
  supportIsVisible = false,
}) {
  return (
    <div className="container-sidebar">
      <SmallSide supportIsVisible={supportIsVisible} />
      <div className="right-side-sidebar sidebar-column">
        <img src={logo} alt="logo" className="logo" />

        <div className="footer-text">
          <h3>{title}</h3>
          <p>{observation}</p>
        </div>
      </div>
    </div>
  );
}
