import React, { createRef, useMemo, useState, useEffect } from 'react';

import useLocalStorage from 'hooks/useLocalStorage';
import LocalizationForm from './LocalizationForm';

export default function Localization() {
  const form = createRef(null);

  const [storageValues, setStorageValues] = useLocalStorage('@step-two');
  const [canBack] = useLocalStorage('@can-back');
  const [isCompleted, setCompleted] = useState(false);

  const initialValues = useMemo(() => {
    const emptyValues = {
      country: { Pais: { id: '76', nome: 'Brasil' } },
      state: '',
      city: '',
      cep: '',
      street: '',
      neighborhood: '',
      number: '',
      complement: '',
    };
    if (!storageValues) {
      setStorageValues(emptyValues);
    }

    return storageValues;
  }, [setStorageValues, storageValues]);

  useEffect(() => {
    if (canBack) {
      return;
    }

    // Utilizado pra verificar todos os itens, menos o obj "complemento"
    const cloneInitialValues = (({ complement, ...values }) => values)(
      initialValues
    );

    if (Object.values(cloneInitialValues).every((item) => item !== '')) {
      setCompleted(true);
    }
  }, [storageValues, canBack, initialValues]);

  function handleSave() {
    setStorageValues(form.current.values);
  }

  return (
    <LocalizationForm
      form={form}
      isCompleted={isCompleted}
      onChangeSave={handleSave}
      initialValues={initialValues}
    />
  );
}
