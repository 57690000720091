export default class APIError extends Error {
  response;

  body;

  constructor(response, body) {
    super();

    this.name = 'APIError';
    this.response = response;
    this.message =
      body.message || `${response.status} - ${response.statusText}`;
  }
}
