import React, { useContext, createRef, useState } from 'react';
import { Formik, Form } from 'formik';

import Select from 'components/Select';
import SelectYear from 'components/Select/SelectYear';
import SelectMonth from 'components/Select/SelectMonth';
import BottomNavigation from 'components/wizard/BottomNavigation';
import FormButton from 'components/FormButton';
import InputSearch from 'components/Input/InputSearch';
import FormTitle from 'components/FormTitle';

import { currentSituation, formationLevel } from 'constants/selectData';

import educationIcon from 'assets/img/steps/education-icon.svg';

import schema from 'schemas/AcademicEducation';
import AcademicEducationService from 'services/AcademicEducationService';

import useCanBackStep from 'hooks/useCanBackStep';
import useLoading from 'hooks/useLoading';

import { StepContext } from 'contexts/StepContext';
import useAPICall from 'hooks/useAPICall';

export default function AcademicEducationForm({
  initialValues,
  allowBackStep,
  onChangeAcademicEducation,
  canCancel,
  onCancel,
  isChangeResume = false,
}) {
  const { handleCanBackStepStorage } = useCanBackStep();
  const { setLoader, getLoaderFromName, removeLoader } = useLoading();
  const { setCurrentStep } = useContext(StepContext);
  const { handleAPICall } = useAPICall();

  const [courses, setCourses] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const form = createRef(null);

  async function getInstitutionsByName(name) {
    setLoader('institutionName');

    try {
      const response = await handleAPICall(() =>
        AcademicEducationService.findInsitutionsByName(name)
      );
      setInstitutions(response);
    } catch (error) {
      console.error('INSTITUTION_NAME:ERROR', error);
    } finally {
      removeLoader('institutionName');
    }
  }

  async function getCoursesByName(name) {
    setLoader('courseName');

    try {
      const response = await handleAPICall(() =>
        AcademicEducationService.findCoursesByName(name)
      );
      setCourses(response);
    } catch (error) {
      console.error('COURSE_NAME:ERROR', error);
    } finally {
      removeLoader('courseName');
    }
  }

  async function handleGetCourses(e) {
    await getCoursesByName(e.target.value);
  }

  async function handleGetInstitutions(e) {
    await getInstitutionsByName(e.target.value);
  }

  function handleSave() {
    if (form.current.isValid) {
      onChangeAcademicEducation(form.current.values);
    } else {
      setCurrentStep((prevState) => ({
        ...prevState,
        error: true,
      }));
    }
  }

  function onClickPrev(goToPrev, steps, step) {
    if (steps.indexOf(step) <= 0) {
      return;
    }

    handleCanBackStepStorage();
    goToPrev();
  }

  React.useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    window._loq?.push(['tag', 'Educação Academica', true, true]);
  }, []);

  return (
    <Formik
      innerRef={form}
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={schema}
      validateOnMount
    >
      {({ setFieldValue, values, errors, touched }) => (
        <Form className="av-tooltip tooltip-label-bottom content-form">
          <div className="content-row row">
            <div className="form-title col-12">
              <FormTitle icon={educationIcon} title="Formação Acadêmica" />
            </div>
            <div className="col-12 col-lg-6">
              <Select
                label="Escolha Nível de Formação"
                id="formationLevel"
                fieldName="formationLevel"
                placeholder="Ex: Ensino Fundamental"
                options={formationLevel}
                value={values.formationLevel}
                onChange={setFieldValue}
                errors={errors.formationLevel}
                touched={touched.formationLevel}
              />

              <InputSearch
                label="Nome da Instituição"
                placeholder="Ex: Colégio maria conceição"
                fieldName="institutionName"
                value={values.institutionName.Instituicao?.nome}
                data={institutions}
                onKeyUp={handleGetInstitutions}
                onChange={setFieldValue}
                errors={errors.institutionName}
                touched={touched.institutionName}
                isLoading={getLoaderFromName('institutionName')}
              />
            </div>

            <div className="col-12 col-lg-6">
              <InputSearch
                className={
                  values.formationLevel.value === 4 ||
                  values.formationLevel.value === 5
                    ? 'hiddenField'
                    : 'visible'
                }
                label="Nome do Curso"
                placeholder="Ex: Designer de interiores"
                fieldName="courseName"
                value={values.courseName.Curso?.nome}
                data={courses}
                onKeyUp={handleGetCourses}
                onChange={setFieldValue}
                errors={errors.courseName}
                touched={touched.courseName}
                isLoading={getLoaderFromName('courseName')}
              />

              <Select
                label="Escolha a Situação Atual"
                id="currentSituation"
                fieldName="currentSituation"
                placeholder="Ex: Incompleto"
                options={currentSituation}
                value={values.currentSituation}
                onChange={setFieldValue}
                errors={errors.currentSituation}
                touched={touched.currentSituation}
              />
            </div>

            <div className="col-6">
              <SelectMonth
                label="Mês de Início"
                id="startMonth"
                fieldName="startMonth"
                value={values.startMonth}
                onChange={setFieldValue}
                errors={errors.startMonth}
                touched={touched.startMonth}
              />
            </div>

            <div className="col-6">
              <SelectYear
                label="Ano de Início"
                id="startYear"
                fieldName="startYear"
                value={values.startYear}
                onChange={setFieldValue}
                errors={errors.startYear}
                touched={touched.startYear}
              />
            </div>

            <div className="col-6">
              <SelectMonth
                label="Mês de Conclusão"
                id="conclusionMonth"
                fieldName="conclusionMonth"
                value={values.conclusionMonth}
                onChange={setFieldValue}
                errors={errors.conclusionMonth}
                touched={touched.conclusionMonth}
              />
            </div>

            <div className="col-6">
              <SelectYear
                label="Ano de Conclusão"
                id="conclusionYearEducation"
                fieldName="conclusionYearEducation"
                value={values.conclusionYearEducation}
                startByStartYear={values.startYear}
                onChange={setFieldValue}
                errors={errors.conclusionYearEducation}
                touched={touched.conclusionYearEducation}
              />
            </div>
          </div>

          {isChangeResume || !allowBackStep ? (
            <FormButton
              onCancel={onCancel}
              onSave={handleSave}
              canCancel={canCancel}
            />
          ) : (
            <BottomNavigation
              onClickNext={handleSave}
              onClickPrev={onClickPrev}
              className="justify-content-center"
              prevLabel={allowBackStep ? 'Voltar' : 'Cancelar'}
              nextLabel="Salvar"
            />
          )}
        </Form>
      )}
    </Formik>
  );
}
