import { useState } from 'react';

export default function useDisableFields() {
  const [disableds, setDisableds] = useState([]);

  function setDisabledFields(fields) {
    setDisableds((prevState) => [...prevState, ...fields]);
  }

  function removeDisabledFields() {
    setDisableds([]);
  }

  function getDisabledFieldFromName(field) {
    return !!disableds.find((disabled) => disabled === field);
  }

  return { getDisabledFieldFromName, setDisabledFields, removeDisabledFields };
}
