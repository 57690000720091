import * as Yup from 'yup';

export default Yup.object().shape({
  fullname: Yup.string()
    .min(2, 'Minimo de 2 letras')
    .required('Campo obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: Yup.string()
    .min(8, 'Minimo de 10 número')
    .required('Campo obrigatório'),
  nacionality: Yup.string().required('Campo obrigatório'),
  maritalStatus: Yup.object().required('Campo obrigatório'),
  pcd: Yup.object().required('Campo obrigatório'),
  gender: Yup.object().required('Campo obrigatório'),
  bornDate: Yup.date().required('Campo obrigatório'),
});
