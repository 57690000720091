import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { HashContext } from 'contexts/HashContext';
import HashService from 'services/HashService';
import ViewError from 'views/error';
import useSentryContext from 'hooks/useSentryContext';
import useAPICall from 'hooks/useAPICall';

export default function WrapperValidate({ children, match }) {
  const { params } = match;

  const isAPICalled = useRef(false);
  const { onChangeHash, onChangeDecodedHash } = useContext(HashContext);
  const { handleAPICall } = useAPICall();

  const [isLoading, setIsLoading] = useState(false);
  const [successDecode, setSuccessDecode] = useState(false);
  const [decodedHash, setDecodedHash] = useState({});

  useEffect(() => {
    onChangeHash(params.hash);
  }, [onChangeHash, params.hash]);

  useSentryContext({ hash: params.hash, decodedHash });

  const handleDecodeHash = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await handleAPICall(() =>
        HashService.decodeHash(params.hash)
      );

      if (response && !response.error) {
        onChangeDecodedHash(response);
        setDecodedHash(response); // is only used to set in sentry context
        setSuccessDecode(true);
      }
    } finally {
      isAPICalled.current = true;
      setIsLoading(false);
    }
  }, [onChangeDecodedHash, params.hash]);

  useEffect(() => {
    if (isAPICalled.current) {
      return;
    }

    handleDecodeHash();
  }, [handleDecodeHash]);

  return (
    <>
      {isLoading ? (
        <div className="loading" />
      ) : (
        <>{successDecode ? <>{children}</> : <ViewError />}</>
      )}
    </>
  );
}
