/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React, { forwardRef } from 'react';

import person from 'assets/img/pdf/person.png';
import bizutlogo from 'assets/logos/logo_bizut.png';

import { getInitialsFullname, formatDate } from 'helpers/Utils';

export default forwardRef((props, ref) => {
  const {
    personalData,
    localization,
    academicEducation,
    professionalExperience,
    image,
    isPreview,
  } = props;
  const name = getInitialsFullname(personalData ? personalData.fullname : '');

  function isCurrentJob(currentJob, item) {
    if (!currentJob) {
      return `${item.conclusionMonth?.label} de ${item.conclusionYearExperience?.label}`;
    }

    return 'o momento';
  }

  return (
    <div id="preview">
      <section
        ref={ref}
        className={`example1-container ${
          isPreview ? 'example1-container-preview' : 'example1-container-pdf'
        }`}
      >
        <section
          className="w-100 background-header"
          style={{ background: props.color }}
        >
          <img
            src={image || person}
            alt="person"
            className="img-rounded image-candidate"
          />
        </section>
        <section className="candidate-bio">
          <h1>
            {name[0]} {name[1] ? name[1] : ''}
          </h1>
        </section>
        <section id="resume-content">
          <div className="content__h-divisor" />

          <div className="d-flex">
            <div className="left-content">
              <div className="resume-topic">
                <h2>DADOS PESSOAIS</h2>
                <ul>
                  <li>{personalData.email}</li>
                  <li>{personalData.phone}</li>
                  <li>{personalData.maritalStatus.label}</li>
                  {personalData.pcd.value === 0 ? null : (
                    <li>{personalData.pcd.label}</li>
                  )}
                  <li>{formatDate(personalData.bornDate)}</li>
                </ul>
              </div>

              <div className="resume-topic">
                <h2>LOCALIZAÇÃO</h2>
                {localization && (
                  <ul>
                    <li>
                      {localization.street}, {localization.number}
                    </li>
                    <li>{localization.complement}</li>
                    <li>
                      {localization.city.Cidade.nome} -{' '}
                      {localization.state.Estado.nome}
                    </li>
                    <li>{localization.neighborhood}</li>
                    <li>{localization.country.Pais.nome}</li>
                    <li>{localization.cep}</li>
                  </ul>
                )}
              </div>
            </div>

            <div className="content__v-divisor" />

            <div className="right-content">
              <div className="resume-topic">
                <h2>FORMAÇÃO ACADÊMICA</h2>
                <div>
                  {academicEducation.map((item, index) => (
                    <ul className="resume-academic" key={index}>
                      <li>{item.institutionName.Instituicao.nome}</li>
                      <li>
                        {item.formationLevel.value > 4 &&
                          item.formationLevel.value < 5 &&
                          `${item.courseName.Curso.nome} - `}
                        {item.formationLevel.label}
                      </li>
                      <li>
                        {item.startMonth.label} de {item.startYear.label}-{' '}
                        {item.conclusionMonth.label} de{' '}
                        {item.conclusionYearEducation.label}
                      </li>
                    </ul>
                  ))}
                </div>
              </div>

              {professionalExperience[0] ? (
                <div className="resume-topic">
                  <h2>EXPÊRIENCIA PROFISSIONAL</h2>
                  <div>
                    {professionalExperience.map((item, index) => (
                      <React.Fragment key={index}>
                        <ul className="resume-experiences">
                          <li>{item.company}</li>
                          <li>{item.companyPosition}</li>
                          <li>
                            {item.startMonth.label} de {item.startYear.label}-{' '}
                            {isCurrentJob(item.currentJob, item)}
                          </li>
                        </ul>
                        {item.description && (
                          <div
                            className="description"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </section>
        <div className="page-footer-other">
          <img className="logo" src={bizutlogo} alt="logo" />
        </div>
      </section>
    </div>
  );
});
