import React, {
  createRef,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';

import useCanBackStep from 'hooks/useCanBackStep';
import useLocalStorage from 'hooks/useLocalStorage';
import { HashContext } from 'contexts/HashContext';
import PersonalDataModal from './PersonalDataModal';
import PersonalDataForm from './PersonalDataForm';

export default function PersonalDataRegister() {
  useCanBackStep();
  const { isArteris } = useContext(HashContext);

  const form = createRef(null);
  const [storageValues, setStorageValues] = useLocalStorage('@step-one');
  const [canBack] = useLocalStorage('@can-back');
  const [isCompleted, setCompleted] = useState(false);

  const initialValues = useMemo(() => {
    const emptyValues = {
      fullname: '',
      email: '',
      phone: '',
      nacionality: '',
      maritalStatus: '',
      pcd: '',
      gender: '',
      bornDate: '',
    };

    if (!storageValues && isArteris) {
      const obj = {
        ...emptyValues,
        cpf: '',
      };

      setStorageValues(obj);
    }

    if (!storageValues && !isArteris) {
      setStorageValues(emptyValues);
    }

    return storageValues;
  }, [isArteris, setStorageValues, storageValues]);

  useEffect(() => {
    if (canBack) {
      return;
    }

    if (Object.values(initialValues).every((obj) => obj !== '')) {
      setCompleted(true);
    }
  }, [storageValues, canBack, initialValues]);

  function handleSave() {
    setStorageValues(form.current.values);
  }

  return (
    <>
      <PersonalDataModal />
      <PersonalDataForm
        isArteris={isArteris}
        onChangeSave={handleSave}
        isCompleted={isCompleted}
        initialValues={initialValues}
        form={form}
      />
    </>
  );
}
