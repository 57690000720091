import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react';

import { HashContext } from 'contexts/HashContext';
import { StepContext } from 'contexts/StepContext';

import paper from 'assets/animations/paper-plane.json';

import './styles.scss';

export default function Finish() {
  const { hash } = useContext(HashContext);
  const { setCurrentStep } = useContext(StepContext);
  const history = useHistory();

  useEffect(() => {
    setCurrentStep({
      name: 'finish',
      title: 'Parabéns! :)',
    });
  }, [setCurrentStep]);

  useEffect(() => {
    const timer = setTimeout(() => {
      history.replace(`/visualizar/${hash}`, null);
    }, 5000);

    return () => clearTimeout(timer);
  }, [hash, history]);

  React.useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    window._loq?.push(['tag', 'Finish', true, true]);
  }, []);

  return (
    <div className="resume-form-container finish-form">
      <div
        className="text-center content-form"
        style={{ position: 'relative' }}
      >
        <div className="d-flex flex-column justify-content-center mb-auto mt-auto position-relative">
          <Lottie animationData={paper} loop className="lottie" />;
        </div>
        <div className="finish-text">
          <span>Aguarde, estamos montando o seu currículo...</span>
        </div>
      </div>
    </div>
  );
}
