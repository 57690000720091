import * as Sentry from '@sentry/browser';

export default function useSentryContext({ hash, decodedHash, email }) {
  const {
    agregador_id: agregadorId,
    empresa_id: empresaId,
    matriz_id: matrizId,
  } = decodedHash;

  Sentry.setContext('contexto', {
    hash,
    agregadorId,
    empresaId,
    matrizId,
    email,
  });
}
