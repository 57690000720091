import HttpClient from './utils/HttpClient';

class ResumeService {
  httpClient;

  constructor() {
    this.httpClient = new HttpClient('https://bizut.me');
  }

  async registerResume(urlenconded) {
    return this.httpClient.postEnconded(
      '/users/cadastrar_curriculo_ajax',
      urlenconded
    );
  }
}

export default new ResumeService();
