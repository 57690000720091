import React, { createContext, useState, useEffect } from 'react';

export const HashContext = createContext();

export default ({ children }) => {
  const [hash, setHash] = useState('');
  const [decodedHash, setDecodedHash] = useState({});
  const [isArteris, setArteris] = useState(false);

  useEffect(() => {
    if (decodedHash.matriz_id === '4513') {
      setArteris(true);
    } else {
      setArteris(false);
    }
  }, [decodedHash]);

  return (
    <HashContext.Provider
      value={{
        hash,
        decodedHash,
        isArteris,
        onChangeDecodedHash: setDecodedHash,
        onChangeHash: setHash,
      }}
    >
      {children}
    </HashContext.Provider>
  );
};
