import React from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import './styles.scss';

export default function ProfessionalExperienceItem({
  data,
  onChangeEdit,
  onChangeRemove,
}) {
  return (
    <div className="resume-card-experience resume-card mb-3 mt-2">
      <div className="resume-card-body">
        <p className="px-3 card-text desktop">
          <strong>{data.companyPosition}</strong> - {data.company}
        </p>

        <p className="card-text mobile">
          <strong>{data.companyPosition}</strong>
        </p>
        <p className="card-text mobile">{data.company}</p>

        <div className="d-flex justify-content-start ml-3 mobile">
          <div className="card-text">
            {data.startMonth.label} de {data.startYear.label} -{' '}
            {data.currentJob ? (
              <span>ate o momento</span>
            ) : (
              <span>
                {data.conclusionMonth.label} de{' '}
                {data.conclusionYearExperience.label}
              </span>
            )}
          </div>
        </div>

        <div
          className="pt-2 ellipsis list-description"
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      </div>

      <div className="resume-card-footer">
        <div className="footer-date desktop">
          <span className="card-text">
            {data.startMonth.label} de {data.startYear.label}
          </span>

          <span> - </span>

          {data.currentJob ? (
            <span>ate o momento</span>
          ) : (
            <span>
              {data.conclusionMonth.label} de{' '}
              {data.conclusionYearExperience.label}
            </span>
          )}
        </div>

        <div className="footer-buttons">
          <button
            type="button"
            className="btn-with-icon"
            onClick={onChangeEdit}
          >
            <HiPencil size={16} />
            <span>Editar</span>
          </button>
          <button
            type="button"
            className="btn-with-icon remove"
            onClick={onChangeRemove}
          >
            <HiTrash size={16} />
            <span>Remover</span>
          </button>
        </div>
      </div>
    </div>
  );
}
