import * as Yup from 'yup';

const defaultSchema = Yup.object().shape({
  hasProfessionalExperience: Yup.object().required('Campo obrigatório'),
  company: Yup.string().when('hasProfessionalExperience', {
    is: (hasProfessionalExperience) =>
      hasProfessionalExperience?.value === 'yes',
    then: Yup.string().required('Campo obrigatório'),
  }),
  companyPosition: Yup.string().when('hasProfessionalExperience', {
    is: (hasProfessionalExperience) =>
      hasProfessionalExperience?.value === 'yes',
    then: Yup.string().required('Campo obrigatório'),
  }),
  startMonth: Yup.object().when('hasProfessionalExperience', {
    is: (hasProfessionalExperience) =>
      hasProfessionalExperience?.value === 'yes',
    then: Yup.object().required('Campo obrigatório'),
  }),
  startYear: Yup.object().when('hasProfessionalExperience', {
    is: (hasProfessionalExperience) =>
      hasProfessionalExperience?.value === 'yes',
    then: Yup.object().required('Campo obrigatório'),
  }),
  currentJob: Yup.boolean().when('hasProfessionalExperience', {
    is: (hasProfessionalExperience) =>
      hasProfessionalExperience?.value === 'yes',
    then: Yup.boolean().required('Campo obrigatório'),
  }),
  conclusionMonth: Yup.object().when('hasProfessionalExperience', {
    is: (hasProfessionalExperience) =>
      hasProfessionalExperience?.value === 'yes',
    then: Yup.object().when('currentJob', {
      is: (currentJob) => currentJob === false,
      then: Yup.object().required('Campo obrigatório'),
    }),
  }),

  conclusionYearExperience: Yup.object().test(
    'verify-bigger-than-startYear',
    (item, schema) => {
      if (schema.parent.startYear?.value > item?.value) {
        return schema.createError({
          message: 'Ano de conclusão não pode ser menor do que o ano de início',
          path: 'conclusionYearExperience',
        });
      }

      if (schema.parent.hasProfessionalExperience?.value === 'yes') {
        if (schema.parent.currentJob === false && !item) {
          return schema.createError({
            message: 'Campo obrigatório',
            path: 'conclusionYearExperience',
          });
        }
        return true;
      }

      return true;
    }
  ),
});

const secondarySchema = Yup.object().shape({
  company: Yup.string().required('Campo obrigatório'),
  companyPosition: Yup.string().required('Campo obrigatório'),
  startMonth: Yup.object().required('Campo obrigatório'),
  startYear: Yup.object().required('Campo obrigatório'),
  currentJob: Yup.boolean().required('Campo obrigatório'),
  conclusionMonth: Yup.object().when('currentJob', {
    is: (currentJob) => currentJob === false,
    then: Yup.object().required('Campo obrigatório'),
  }),
  conclusionYearExperience: Yup.object().test(
    'verify-bigger-than-startYear',
    (item, schema) => {
      if (schema.parent.startYear?.value > item?.value) {
        return schema.createError({
          message: 'Ano de conclusão não pode ser menor do que o ano de início',
          path: 'conclusionYearExperience',
        });
      }

      if (schema.parent.currentJob === false && !item) {
        return schema.createError({
          message: 'Campo obrigatório',
          path: 'conclusionYearExperience',
        });
      }

      return true;
    }
  ),
});

export { defaultSchema, secondarySchema };
