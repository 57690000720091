import HttpClient from 'services/utils/HttpClient';
import { BASE_URL } from 'services/utils/URL';

class LocalizationService {
  httpClient;

  constructor() {
    this.httpClient = new HttpClient(BASE_URL);
  }

  listAllCountries() {
    return this.httpClient.get('/endpointcurriculos/paises');
  }

  listAllStatesByCountryId(countryId) {
    return this.httpClient.get(`/endpointcurriculos/estados/${countryId}`);
  }

  listCitiesByStateId(stateId) {
    return this.httpClient.get(`/endpointcurriculos/cidades/${stateId}`);
  }

  findCep(cep) {
    return this.httpClient.get(`/endpointcurriculos/ceps/${cep}`);
  }
}

export default new LocalizationService();
