/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ReactQuill from 'react-quill';
import { Label } from 'reactstrap';
import FormGroup from 'components/FormGroup';

import 'react-quill/dist/quill.snow.css';
import './styles.scss';

export default function Editor({ label, value, onChange, fieldName }) {
  const handleChange = (val) => {
    onChange(fieldName, `${val}`);
  };

  return (
    <FormGroup>
      <Label style={{ fontSize: '0.75rem' }}>{label}</Label>
      <ReactQuill value={value} onChange={(e) => handleChange(e)} />
    </FormGroup>
  );
}
