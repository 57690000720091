import React from 'react';

import Button from 'components/Button';

export default function FormButton({ onCancel, onSave, canCancel = true }) {
  return (
    <div className="d-flex w-100 form-buttons">
      {canCancel ? (
        <>
          <Button
            label="Cancelar"
            color="secondary"
            className="w-100 mr-2"
            onClick={onCancel}
          />
          <Button
            type="submit"
            label="Salvar"
            className="w-100"
            onClick={onSave}
          />
        </>
      ) : (
        <>
          <div className="d-flex w-100 form-buttons">
            <Button
              label="Cancelar"
              color="secondary"
              className="w-100 mr-2"
              onClick={onCancel}
            />
            <Button
              type="submit"
              label="Salvar"
              className="w-100"
              onClick={onSave}
            />
          </div>
        </>
      )}
    </div>
  );
}
