import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import FormGroup from 'components/FormGroup';
import useDeviceDetect from 'hooks/useDeviceDetect';
import customStyles from './customStyles';

const getYears = () => {
  const currentYear = new Date().getFullYear() + 6;
  const years = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 1960; i <= currentYear; i++) {
    years.push({ value: i, label: i });
  }

  return years.reverse();
};

export default function SelectYear({
  label,
  fieldName,
  isMulti,
  value,
  errors,
  touched,
  onChange,
  className,
  startByStartYear,
  options = getYears(),
}) {
  const { isMobile } = useDeviceDetect();
  const currentYear = new Date().getFullYear();

  const years = getYears();

  const startYear = () => {
    const result = years.filter((item) => item.value <= currentYear);
    return result;
  };

  const conclusionYearEducation = () => {
    if (!startByStartYear) {
      return [];
    }

    return years;
  };

  const conclusionYearExperience = () => {
    if (!startByStartYear) {
      return [];
    }

    const arr = years.filter(
      (item) =>
        item.value <= new Date().getFullYear() &&
        item.value >= startByStartYear.value
    );

    return arr;
  };

  const selectOptions = () => {
    if (fieldName === 'startYear') {
      return startYear();
    }

    if (fieldName === 'conclusionYearEducation') {
      return conclusionYearEducation();
    }

    if (fieldName === 'conclusionYearExperience') {
      return conclusionYearExperience();
    }

    return options;
  };

  const handleChange = (val) => {
    onChange(fieldName, val);
  };

  return (
    <FormGroup className="w-100">
      <Label style={{ fontSize: '0.75rem' }}>{label}</Label>
      <Select
        placeholder="Ex: 2022"
        className={`react-select ${className}`}
        classNamePrefix="react-select"
        options={selectOptions()}
        isMulti={isMulti}
        onChange={handleChange}
        styles={customStyles}
        value={value}
        isSearchable={!isMobile}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? '' : 'Nenhum resultado encontrado'
        }
      />
      {errors && touched && (
        <div className="invalid-feedback d-block">{errors}</div>
      )}
    </FormGroup>
  );
}
