/* eslint-disable no-return-assign */
import React from 'react';
import { Label } from 'reactstrap';
import { Field } from 'formik';
import FormGroup from 'components/FormGroup';
import { formatPhone, formatCPF } from 'helpers/Utils';

export default function CustomInput({
  label,
  fieldName,
  value,
  type = 'date',
  onChange,
  placeholder,
  errors,
  touched,
  validate,
  className,
}) {
  const handleChange = (val) => {
    if (fieldName === 'phone') {
      const phone = formatPhone(val.target.value);

      onChange(fieldName, phone);
      return;
    }

    if (fieldName === 'cpf') {
      const cpf = formatCPF(val.target.value);

      onChange(fieldName, cpf);
      return;
    }

    onChange(fieldName, val.target.value);
  };

  return (
    <FormGroup className={className}>
      <Label style={{ fontSize: '0.75rem' }}>{label}</Label>
      <Field
        validate={validate}
        placeholder={placeholder}
        className="form-control"
        type={type}
        name={fieldName}
        value={value}
        onChange={handleChange}
        maxLength="15"
      />
      {errors && touched && (
        <div className="invalid-feedback d-block">{errors}</div>
      )}
    </FormGroup>
  );
}
