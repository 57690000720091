import React from 'react';

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);

  const userAgent =
    typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

  React.useEffect(() => {
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setMobile(mobile);
  }, [userAgent]);

  return { isMobile };
}
