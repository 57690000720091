import React, { createRef, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { HashContext } from 'contexts/HashContext';

import useLocalStorage from 'hooks/useLocalStorage';
import PersonalDataForm from './PersonalDataForm';

export default function PersonalDataEdit() {
  const { hash, isArteris } = useContext(HashContext);
  const form = createRef(null);
  const history = useHistory();
  const [storageValues, setStorageValues] = useLocalStorage('@step-one');

  const initialValues = useMemo(() => {
    return storageValues;
  }, [storageValues]);

  function handleSave() {
    setStorageValues(form.current.values);
    history.replace(`/visualizar/${hash}`, null);
  }

  function handleCancel() {
    history.replace(`/visualizar/${hash}`, null);
  }

  return (
    <div className="form-edit">
      <PersonalDataForm
        initialValues={initialValues}
        isArteris={isArteris}
        form={form}
        isEdit
        onChangeSave={handleSave}
        onChangeCancel={handleCancel}
      />
    </div>
  );
}
