/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import facebook from 'assets/img/sidebar/facebook.svg';
import instagram from 'assets/img/sidebar/instagram.svg';
import linkedin from 'assets/img/sidebar/linkedin.svg';
import twitter from 'assets/img/sidebar/twitter.svg';
import tips from 'assets/img/sidebar/tips.svg';
import support from 'assets/img/sidebar/support.svg';

import useLocalStorage from 'hooks/useLocalStorage';
import useZendesk from 'hooks/useZendesk';

import './styles.scss';

export default function SmallSide({ supportIsVisible = false }) {
  const [tipsOpened, setTipsOpened] = useLocalStorage('@tips-opened', false);
  const [needHelp, setNeedHelp] = useLocalStorage('@need-help', true);

  const { handleOpenZendesk, hasUnreadMessages } = useZendesk();

  function handleOpenTips() {
    setTipsOpened(true);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setNeedHelp(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, [setNeedHelp]);

  return (
    <div className="left-side-sidebar sidebar-column">
      {supportIsVisible && (
        <div className="top-sidebar">
          <Link to="/tips" target="_blank">
            <div className="tips-content">
              <img
                src={tips}
                className="tips-icon"
                alt="tips-icon"
                onClick={handleOpenTips}
              />
              {!tipsOpened && <div className="point" />}
            </div>
          </Link>
          <div className="support-content">
            {hasUnreadMessages && <div className="pulse" />}
            <button
              className="btn p-0"
              type="button"
              onClick={handleOpenZendesk}
            >
              <img src={support} className="support-icon" alt="support-icon" />
            </button>
            {needHelp && (
              <div className="help-indicator invalid-feedback d-block">
                Precisa de ajuda?
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className={`social-medias sidebar-column ${
          !supportIsVisible && 'mt-auto'
        }`}
      >
        <a
          href="https://br.linkedin.com/company/bizut"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="linkedin" />
        </a>
        <a
          href="https://www.instagram.com/bizutrh/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="instagram" />
        </a>
        <a
          href="https://pt-br.facebook.com/BizutBrasil/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="facebook" />
        </a>
        <a href="https://twitter.com/bizut" target="_blank" rel="noreferrer">
          <img src={twitter} alt="twitter" />
        </a>
        <div className="line" />
      </div>
    </div>
  );
}
