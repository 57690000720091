import APIError from 'errors/APIError';

class HttpClient {
  baseURL;

  constructor(url) {
    this.baseURL = url;
  }

  async post(path, data, contentType = 'application/json') {
    let body = null;

    const response = await fetch(`${this.baseURL}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': contentType,
        Accept: 'application/json',
      },
      body: JSON.stringify({ ...data }),
      keepalive: true,
    });
    const contentTypeHeaders = response.headers.get('Content-Type');

    if (contentTypeHeaders?.includes('application/json')) {
      body = await response.json();
    }

    if (response.ok) {
      return body;
    }

    throw new APIError(response, body);
  }

  async put(path, data, contentType = 'application/json') {
    let body = null;

    const response = await fetch(`${this.baseURL}${path}`, {
      method: 'PUT',
      headers: {
        'Content-Type': contentType,
        Accept: 'application/json',
      },
      body: JSON.stringify({ ...data }),
      keepalive: true,
    });
    const contentTypeHeaders = response.headers.get('Content-Type');

    if (contentTypeHeaders?.includes('application/json')) {
      body = await response.json();
    }

    if (response.ok) {
      return body;
    }

    throw new APIError(response, body);
  }

  async get(path, data) {
    let body = null;

    const response = await fetch(`${this.baseURL}${path}`, {
      method: 'GET',
      body: data && JSON.stringify({ ...data }),
      keepalive: true,
    });

    // const contentTypeHeaders = response.headers.get('Content-Type');

    // if (contentTypeHeaders?.includes('application/json')) {
    // }

    if (response.ok) {
      body = await response.json();
      return body;
    }

    throw new APIError(response, body);
  }

  async postEnconded(path, urlencoded) {
    let body = null;
    const response = await fetch(`${this.baseURL}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: urlencoded,
      redirect: 'follow',
      keepalive: true,
    });

    if (response.ok) {
      body = await response.json();
    }

    return body;
  }

  getUrl(path) {
    return `${this.baseURL}${path}`;
  }
}

export default HttpClient;
