import React from 'react';
import { Label } from 'reactstrap';
import { Field } from 'formik';
import FormGroup from 'components/FormGroup';

export default function Input({
  label,
  placeholder,
  fieldType = 'text',
  validate,
  errors,
  touched,
  fieldName,
  as = 'input',
  onKeyUp,
}) {
  return (
    <FormGroup>
      <Label style={{ fontSize: '0.75rem' }}>{label}</Label>
      <Field
        as={as}
        className="form-control"
        name={fieldName}
        placeholder={placeholder}
        type={fieldType}
        validate={validate}
        onKeyUp={onKeyUp}
      />
      {errors && touched && (
        <div className="invalid-feedback d-block">{errors}</div>
      )}
    </FormGroup>
  );
}
