import { useCallback, useRef } from 'react';

export default function useAPICall() {
  const attempts = useRef(0);

  const handleAPICall = useCallback(
    async (callback) => {
      let response = null;

      try {
        attempts.current += 1;

        const result = await callback();

        response = result;
      } catch {
        if (attempts.current < 3) {
          const res = await handleAPICall(callback);

          response = res;
        }
      }

      return response;
    },
    [attempts]
  );

  return { handleAPICall };
}
