import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import FormGroup from 'components/FormGroup';
import useDeviceDetect from 'hooks/useDeviceDetect';
import customStyles from './customStyles';

export default function SelectMonth({
  label,
  fieldName,
  isMulti,
  value,
  errors,
  touched,
  onChange,
  className,
}) {
  const { isMobile } = useDeviceDetect();

  const handleChange = (val) => {
    onChange(fieldName, val);
  };

  const months = [
    { value: 'January', label: 'Janeiro' },
    { value: 'February', label: 'Fevereiro' },
    { value: 'March', label: 'Março' },
    { value: 'April', label: 'Abril' },
    { value: 'May', label: 'Maio' },
    { value: 'June', label: 'Junho' },
    { value: 'July', label: 'Julho' },
    { value: 'August', label: 'Agosto' },
    { value: 'September', label: 'Setembro' },
    { value: 'October', label: 'Outubro' },
    { value: 'November', label: 'Novembro' },
    { value: 'December', label: 'Dezembro' },
  ];

  return (
    <FormGroup className="w-100">
      <Label style={{ fontSize: '0.75rem' }}>{label}</Label>
      <Select
        placeholder="Ex: Janeiro"
        className={`react-select ${className}`}
        classNamePrefix="react-select"
        options={months}
        isMulti={isMulti}
        onChange={handleChange}
        value={value}
        isSearchable={!isMobile}
        styles={customStyles}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? '' : 'Nenhum resultado encontrado'
        }
      />
      {errors && touched && (
        <div className="invalid-feedback d-block">{errors}</div>
      )}
    </FormGroup>
  );
}
