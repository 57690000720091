import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { HashContext } from 'contexts/HashContext';

import Dropdown from 'components/Dropdown';
import useLocalStorage from 'hooks/useLocalStorage';

export default function ResumeEdit() {
  const history = useHistory();
  const { hash } = useContext(HashContext);
  const [, setLocalStorage] = useLocalStorage('@step-to-edit');

  const actions = [
    {
      name: 'Dados pessoais',
      value: 1,
    },
    {
      name: 'Endereço',
      value: 2,
    },
    {
      name: 'Formação Acadêmica',
      value: 3,
    },
    {
      name: 'Expêriencia Profissional',
      value: 4,
    },
  ];

  function handleNavigateToEdit(item) {
    setLocalStorage(item.value.toString());
    history.push(`/editar/${hash}`);
  }

  return (
    <Dropdown
      title="Editar"
      actions={actions}
      onChangeDropdownItem={handleNavigateToEdit}
    />
  );
}
