import React, { useState, createContext } from 'react';

export const StepContext = createContext();

export default ({ children }) => {
  const [currentStep, setCurrentStep] = useState({});

  return (
    <StepContext.Provider value={{ currentStep, setCurrentStep }}>
      {children}
    </StepContext.Provider>
  );
};
