/* eslint-disable no-unreachable */
import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import './helpers/Firebase';
import Routes from 'routes';
import AppLocale from './lang';
import { NotificationContainer } from './components/common/react-notifications';
import { getDirection } from './helpers/Utils';

class App extends React.Component {
  constructor(props) {
    super(props);
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add('rtl');
      document.body.classList.remove('ltr');
    } else {
      document.body.classList.add('ltr');
      document.body.classList.remove('rtl');
    }
  }

  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale];

    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <>
          <NotificationContainer />
          <Routes />
        </>
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { currentUser } = authUser;
  const { locale } = settings;
  return { currentUser, locale };
};
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(App);
