import React from 'react';
import { Button as Btn } from 'reactstrap';
import './styles.scss';
import loader from 'assets/animations/loader.svg';

export default function Button({
  label,
  className,
  onClick,
  style,
  isLoading,
  color = 'primary',
  type = 'button',
}) {
  return (
    <Btn
      type={type}
      style={style}
      color={color}
      className={`btn main-button ${className}`}
      onClick={isLoading ? null : onClick}
    >
      {isLoading ? (
        <img src={loader} alt="loader" height={24} width={24} />
      ) : (
        <span>{label}</span>
      )}
    </Btn>
  );
}
