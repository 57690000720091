import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import FormGroup from 'components/FormGroup';
import useDeviceDetect from 'hooks/useDeviceDetect';
import customStyles from './customStyles';

export default function SelectDefault({
  label,
  fieldName,
  options,
  value,
  errors,
  touched,
  onChange,
  onValueId,
  placeholder = 'Selecione',
  getOptionValue,
  getOptionLabel,
  isDisabled,
}) {
  const { isMobile } = useDeviceDetect();

  const handleChange = (val) => {
    onChange(fieldName, val);

    if (fieldName === 'state' || fieldName === 'country') {
      onValueId(val);
    }
  };

  return (
    <FormGroup>
      <Label style={{ fontSize: '0.75rem' }}>{label}</Label>
      <Select
        placeholder={placeholder}
        className="react-select"
        classNamePrefix="react-select"
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={handleChange}
        value={value}
        styles={customStyles}
        isSearchable={!isMobile}
        isDisabled={isDisabled}
      />
      {errors && touched && (
        <div className="invalid-feedback d-block">{errors}</div>
      )}
    </FormGroup>
  );
}
