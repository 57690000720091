/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useMemo, forwardRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';

import { Example1, Example2, Example3 } from 'components/ExamplePDFs';

import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';

SwiperCore.use([Navigation]);

export default forwardRef((props, ref) => {
  const {
    image,
    currentColorIndex,
    colors,
    isPreview,
    storageSteps,
    resumeIsSelected,
  } = props;

  const emptyRef = useRef();
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const [swiperIndex, setSwiperIndex] = useState(0);

  const resumes = useMemo(
    () => [
      <>
        <Example1
          ref={swiperIndex === 0 ? ref : emptyRef}
          isPreview={isPreview}
          image={image}
          color={colors[currentColorIndex]}
          personalData={storageSteps[0]}
          localization={storageSteps[1]}
          academicEducation={storageSteps[2]}
          professionalExperience={storageSteps[3]}
        />
      </>,
      <>
        <Example2
          ref={swiperIndex === 1 ? ref : emptyRef}
          isPreview={isPreview}
          image={image}
          color={colors[currentColorIndex]}
          personalData={storageSteps[0]}
          localization={storageSteps[1]}
          academicEducation={storageSteps[2]}
          professionalExperience={storageSteps[3]}
        />
      </>,
      <>
        <Example3
          ref={swiperIndex === 2 ? ref : emptyRef}
          isPreview={isPreview}
          color={colors[currentColorIndex]}
          personalData={storageSteps[0]}
          localization={storageSteps[1]}
          academicEducation={storageSteps[2]}
          professionalExperience={storageSteps[3]}
        />
      </>,
    ],
    [
      colors,
      ref,
      currentColorIndex,
      image,
      isPreview,
      storageSteps,
      swiperIndex,
    ]
  );

  function handleChangeResume(index) {
    // eslint-disable-next-line no-underscore-dangle
    window._loq?.push(['tag', 'Trocar curriculo', true, true]);
    setSwiperIndex(index);
  }

  return (
    <>
      {resumeIsSelected ? (
        <>{resumes[swiperIndex]}</>
      ) : (
        <Swiper
          className="swiper-container"
          slidesPerView={1}
          onSlideChange={(item) => handleChangeResume(item.realIndex)}
          onSwiper={(swiper) => {
            // Delay execution for the refs to be defined
            setTimeout(() => {
              if (swiper.destroyed) {
                return;
              }

              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;

              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
        >
          {resumes.map((item, index) => (
            <SwiperSlide key={index}>{item}</SwiperSlide>
          ))}
          <div className="resume-swiper mobile">
            <div ref={prevRef} className="button">
              <MdOutlineKeyboardArrowLeft size={20} />
            </div>
            <span>{swiperIndex + 1}</span>
            <div ref={nextRef} className="button">
              <MdOutlineKeyboardArrowRight size={20} />
            </div>
          </div>
        </Swiper>
      )}
    </>
  );
});
