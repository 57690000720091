import React, { useContext, useEffect, createRef, useCallback } from 'react';
import { Formik, Form } from 'formik';

import Select from 'components/Select';
import SelectYear from 'components/Select/SelectYear';
import SelectMonth from 'components/Select/SelectMonth';
import Input from 'components/Input';
import Switch from 'components/Switch';
import FormButton from 'components/FormButton';
import BottomNavigation from 'components/wizard/BottomNavigation';
import FormTitle from 'components/FormTitle';

import professionalIcon from 'assets/img/steps/professional-icon.svg';
import { defaultSchema, secondarySchema } from 'schemas/ProfessionalExperience';

import useCanBackStep from 'hooks/useCanBackStep';
import { StepContext } from 'contexts/StepContext';

import Editor from 'components/Editor';

const opt = [
  { value: 'yes', label: 'Sim' },
  { value: 'not', label: 'Não' },
];

export default function ProfessionalExperienceForm({
  initialValues,
  isEdit,
  allowBackStep,
  showHasExperienceSelect,
  onChangeProfessionalExperienceToStorage,
  onCancel,
}) {
  const form = createRef(null);
  const { handleCanBackStepStorage } = useCanBackStep();
  const { setCurrentStep } = useContext(StepContext);

  function handleSave() {
    if (form.current.isValid) {
      onChangeProfessionalExperienceToStorage(form.current.values);
    } else {
      setCurrentStep((prevState) => ({
        ...prevState,
        error: true,
      }));
    }
  }

  const handleNext = useCallback((goToNext, steps, step) => {
    if (steps.length - 1 <= steps.indexOf(step)) {
      return;
    }

    step.isDone = true;
    goToNext();
  }, []);

  const handlePrev = useCallback(
    (goToPrev, steps, step) => {
      if (steps.indexOf(step) <= 0) {
        return;
      }

      handleCanBackStepStorage();
      goToPrev();
    },
    [handleCanBackStepStorage]
  );

  useEffect(() => {
    setCurrentStep({
      name: 'professionalExperience',
      title: 'Experiência Profissional',
    });
  }, [setCurrentStep]);

  return (
    <Formik
      innerRef={form}
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={
        showHasExperienceSelect ? defaultSchema : secondarySchema
      }
      validateOnMount
    >
      {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
        <Form className="av-tooltip tooltip-label-bottom content-form">
          <div className="content-row row">
            <div className="form-title col-12">
              <FormTitle
                icon={professionalIcon}
                title="Experiência Profissional"
              />
            </div>

            <div className="col-12 col-lg-3">
              {showHasExperienceSelect ? (
                <Select
                  label="Possui experiência profissional"
                  id="hasProfessionalExperience"
                  fieldName="hasProfessionalExperience"
                  options={opt}
                  value={values.hasProfessionalExperience}
                  onChange={setFieldValue}
                  errors={errors.hasProfessionalExperience}
                  touched={touched.hasProfessionalExperience}
                />
              ) : null}
            </div>

            <div className="invisible col-lg-6" />

            {values.hasProfessionalExperience?.value === 'yes' ||
            !showHasExperienceSelect ? (
              <>
                <div className="col-lg-5">
                  <Input
                    label="Empresa"
                    fieldName="company"
                    placeholder="Ex: Bizut"
                    errors={errors.company}
                    touched={touched.company}
                  />
                </div>

                <div className="col-lg-5">
                  <Input
                    label="Cargo"
                    fieldName="companyPosition"
                    placeholder="Ex: Analista de sistemas"
                    errors={errors.companyPosition}
                    touched={touched.companyPosition}
                  />
                </div>

                <div className="switch-desktop col-lg-2 d-none">
                  <span className="font-weight-bold text">Emprego atual</span>

                  <Switch
                    name="currentJob"
                    value={!!values.currentJob}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </div>

                <div className="d-flex col-lg-5">
                  <SelectMonth
                    label="Mês de Início"
                    className="mr-1"
                    id="startMonth"
                    fieldName="startMonth"
                    value={values.startMonth}
                    onChange={setFieldValue}
                    errors={errors.startMonth}
                    touched={touched.startMonth}
                  />

                  <SelectYear
                    label="Ano de Início"
                    id="startYear"
                    className="ml-1"
                    fieldName="startYear"
                    value={values.startYear}
                    onChange={setFieldValue}
                    errors={errors.startYear}
                    touched={touched.startYear}
                  />
                </div>

                <div className="switch-mobile col-12 d-none">
                  <span className="font-weight-bold text-lg-left text">
                    Emprego atual
                  </span>

                  <Switch
                    name="currentJob"
                    value={!!values.currentJob}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </div>

                {!values.currentJob ? (
                  <div className="d-flex col-lg-5">
                    <SelectMonth
                      label="Mês de Saída"
                      id="conclusionMonth"
                      className="mr-1"
                      fieldName="conclusionMonth"
                      value={values.conclusionMonth}
                      onChange={setFieldValue}
                      errors={errors.conclusionMonth}
                      touched={touched.conclusionMonth}
                    />

                    <SelectYear
                      label="Ano de Saída"
                      className="ml-1"
                      id="conclusionYearExperience"
                      fieldName="conclusionYearExperience"
                      startByStartYear={values.startYear}
                      value={values.conclusionYearExperience}
                      onChange={setFieldValue}
                      errors={errors.conclusionYearExperience}
                      touched={touched.conclusionYearExperience}
                    />
                  </div>
                ) : null}

                <div className="col-12">
                  <Editor
                    fieldName="description"
                    label="Descrição"
                    value={values.description || ''}
                    onChange={setFieldValue}
                    errors={errors.descrition}
                  />
                </div>
              </>
            ) : null}
          </div>

          {isEdit || !allowBackStep ? (
            <FormButton onCancel={onCancel} onSave={handleSave} />
          ) : (
            <BottomNavigation
              onClickNext={
                values.hasProfessionalExperience?.value === 'not'
                  ? handleNext
                  : handleSave
              }
              onClickPrev={handlePrev}
              className="justify-content-center"
              prevLabel="Voltar"
              nextLabel={
                values.hasProfessionalExperience?.value === 'not'
                  ? 'Próximo'
                  : 'Salvar'
              }
            />
          )}
        </Form>
      )}
    </Formik>
  );
}
