import HttpClient from 'services/utils/HttpClient';
import { BASE_URL } from 'services/utils/URL';

class EmailService {
  httpClient;

  constructor() {
    this.httpClient = new HttpClient(BASE_URL);
  }

  checkEmail(emailEncoded) {
    return this.httpClient.postEnconded(
      `/users/valida_email_exists_ajax`,
      emailEncoded
    );
  }
}

export default new EmailService();
