import React from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';

export default function AcademicEducationItem({
  data,
  onChangeEdit,
  onChangeRemove,
}) {
  return (
    <div className="resume-card-education resume-card">
      <div className="resume-card-body">
        <div>
          <p className="card-text font-weight-bold mb-1">
            {data.institutionName.Instituicao.nome}
          </p>
          {data.formationLevel.value < 4 || data.formationLevel.value > 5 ? (
            <p className="card-text font-weight-semibold mb-2">
              {data.courseName.Curso.nome}
            </p>
          ) : null}
        </div>

        <div>
          <p className="card-text mb-1">
            {data.formationLevel.label} - {data.currentSituation.label}
          </p>

          {data.currentSituation.value !== 1 ? (
            <p>
              {data.startMonth.label} de {data.startYear.label} -{' '}
              {data.conclusionMonth.label} de{' '}
              {data.conclusionYearEducation.label}
            </p>
          ) : (
            <>
              <p className="card-text mb-1">
                {data.startMonth.label} de {data.startYear.label}
              </p>
              <p>
                Previsão de conclusão {data.conclusionMonth.label} de{' '}
                {data.conclusionYearEducation.label}
              </p>
            </>
          )}
        </div>

        <div className="d-flex justify-content-between mt-3">
          <button
            type="button"
            className="btn-with-icon"
            onClick={onChangeEdit}
          >
            <HiPencil size={16} />
            <span>EDITAR</span>
          </button>
          <button
            type="button"
            className="btn-with-icon"
            onClick={onChangeRemove}
          >
            <HiTrash size={16} fill="#FF0A0A" />
            <span style={{ color: '#FF0A0A', fontWeight: 'bold' }}>APAGAR</span>
          </button>
        </div>
      </div>
    </div>
  );
}
