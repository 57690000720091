import { useEffect } from 'react';

export default function useCanBackStep() {
  function handleCanBackStepStorage() {
    localStorage.setItem('@can-back', 'true');
  }

  useEffect(() => {
    function removeCanBack(e) {
      e.preventDefault();

      localStorage.removeItem('@can-back');
    }

    window.addEventListener('beforeunload', (e) => removeCanBack(e));

    return () => {
      window.removeEventListener('beforeunload', (e) => removeCanBack(e));
    };
  }, []);

  return { handleCanBackStepStorage };
}
