import React from 'react';
import bizutlogo from 'assets/logos/logo_bizut.png';

export default function HeaderDefault() {
  return (
    <div className="container-header">
      <div className="header-view">
        <img src={bizutlogo} alt="bizutlogo" />
      </div>
    </div>
  );
}
