import React from 'react';
import { HeaderView } from 'components/Header';
import './styles.scss';

export default function Tips() {
  return (
    <div className="tips-container">
      <HeaderView />
      <div className="tips-list">
        <ul>
          <li>
            Cuidado com os nomes que você usa em e-mails e endereços de redes
            sociais. Apelidos em geral podem passar uma impressão negativa ao
            avaliador. Para parecer mais profissional, utilize um email apenas
            com o seu nome real e nenhuma informação a mais.
          </li>
          <li>
            Capriche na experiência profissional, essa é uma das partes mais
            importantes do seu currículo, é preciso tratá-la com atenção
            especial. Dê relevância às suas experiências mais atuais e
            relevantes. Dados objetivos e metas atingidas fazem diferença.
          </li>
          <li>
            Se desejar incluir sua foto, tente usar uma simples em que você
            pareça natural e com simpatia. Evite selfies, fotos em trajes de
            banho, em viagens ou festas. Uma simples imagem com boa luz e fundo
            neutro podem contar muito mais a seu favor.
          </li>
          <li>
            Cuidado com a língua portuguesa. Nada mais comprometedor para um
            profissional do que apresentar um currículo com erros de português!
            Revise o seu currículo e, se não tiver certeza sobre a grafia de
            determinada palavra, faça uma consulta online.
          </li>
          <li>
            Para quem está em busca da primeira oportunidade profissional, não
            se preocupe. Deixe claro que não possui experiência e dê ênfase as
            outras partes do seu currículo, especialmente a formação acadêmica.
          </li>
        </ul>
      </div>
    </div>
  );
}
