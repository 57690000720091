import { useState } from 'react';

export default function useLoading() {
  const [loaders, setLoaders] = useState([]);

  function setLoader(loaderName) {
    const loaderAlreadyExists = loaders.find(
      (loader) => loader.name === loaderName
    );

    if (loaderAlreadyExists) return;

    setLoaders((prevState) => [...prevState, { name: loaderName }]);
  }

  function removeLoader(loaderName) {
    setLoaders((prevState) =>
      prevState.filter((loader) => loader.name !== loaderName)
    );
  }

  function getLoaderFromName(loaderName) {
    return !!loaders.find((loader) => loader.name === loaderName);
  }

  return { getLoaderFromName, setLoader, removeLoader };
}
