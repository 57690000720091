import React from 'react';

import Button from 'components/Button';
import './styles.scss';

export default function SuccessfulRegister({ onCloseModal, isJobOpportunity }) {
  return (
    <div className="container">
      <h1>Seu cadastro foi realizado com sucesso</h1>
      {isJobOpportunity ? (
        <p>Sua incrição na vaga foi realizada com sucesso!</p>
      ) : null}

      <Button label="Fechar" onClick={onCloseModal} />
    </div>
  );
}
