export default [
  '#E87737',
  '#231F20',
  '#C4C4C4',
  '#EB4B4B',
  '#E9C612',
  '#7DC643',
  '#5DACB7',
  '#1A6D78',
  '#4484CF',
  '#124D94',
  '#7B6CD7',
  '#2D11DA',
  '#DB4DFF',
  '#AA0AD2',
  '#D708A9',
  '#FF4F84',
  '#B5073B',
  '#830A2E',
  '#81520C',
  '#B16E09',
];
