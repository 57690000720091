import React from 'react';

export default function FormTitle({ icon, title }) {
  return (
    <div className="d-flex align-items-center pb-2">
      <img src={icon} alt="icon" height={38} width={38} />
      <h4 className="text-light-title font-weight-bold mt-3 ml-3">{title}</h4>
    </div>
  );
}
