import * as Yup from 'yup';

export default Yup.object().shape({
  courseName: Yup.object().when('formationLevel', {
    is: (formationLevel) =>
      formationLevel?.value < 4 || formationLevel?.value > 5,
    then: Yup.object().required('Campo obrigatório'),
  }),
  institutionName: Yup.object().required('Campo obrigatório'),
  formationLevel: Yup.object().required('Campo obrigatório'),
  currentSituation: Yup.object().required('Campo obrigatório'),
  startMonth: Yup.object().required('Campo obrigatório'),
  conclusionMonth: Yup.object().required('Campo obrigatório'),
  startYear: Yup.object().required('Campo obrigatório'),
  conclusionYearEducation: Yup.object()
    .test('verify-bigger-than-startYear', (item, schema) => {
      if (!item) {
        return schema.createError({
          message: 'Campo obrigatório',
          path: 'conclusionYearEducation',
        });
      }

      if (schema.parent.startYear?.value > item?.value) {
        return schema.createError({
          message: 'Ano de conclusão não pode ser menor do que o ano de início',
          path: 'conclusionYearEducation',
        });
      }

      return true;
    })
    .required('Campo obrigatório'),
});
