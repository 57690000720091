/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React, { forwardRef } from 'react';
import { getInitialsFullname } from 'helpers/Utils';
import bizutlogo from 'assets/logos/logo_bizut.png';

export default forwardRef((props, ref) => {
  const { localization, personalData, professionalExperience, isPreview } =
    props;
  const name = getInitialsFullname(personalData ? personalData.fullname : '');

  function isCurrentJob(currentJob, item) {
    if (!currentJob) {
      return `${item.conclusionYearExperience?.label}`;
    }

    return 'o momento';
  }

  return (
    <div id="preview">
      <div
        ref={ref}
        className={`example3-container ${
          isPreview ? 'example3-container-preview' : 'example3-container-pdf'
        }`}
      >
        <div className="left-content" style={{ backgroundColor: props.color }}>
          <div className="info">
            <div className="bio">
              <h1>
                {name[0]} {name[1] ? name[1] : ''}
              </h1>
            </div>
            <div className="contact">
              <h2>Contato</h2>

              <ul>
                <li>{props.personalData.phone}</li>
                <li className="email">{props.personalData.email}</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="right-content">
          <section className="address">
            <h2>ENDEREÇO</h2>
            {localization && (
              <ul>
                <li>
                  {localization.street}, {localization.number}
                </li>
                <li>{localization.complement}</li>
                <li>
                  {localization.city.Cidade.nome} -{' '}
                  {localization.state.Estado.nome}
                </li>
                <li>{localization.neighborhood}</li>
                <li>{localization.country.Pais.nome}</li>
                <li>{localization.cep}</li>
              </ul>
            )}
          </section>

          <section className="academic-education">
            <h2>FORMAÇÃO ACADÊMICA</h2>

            <div>
              {props.academicEducation.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ul key={index} className="resume-academic">
                  <li>{item.institutionName.Instituicao.nome}</li>
                  {item.courseName && <li>{item.courseName.Curso.nome}</li>}
                  <li>
                    {item.formationLevel.label} | {item.startYear?.label} -{' '}
                    {item.conclusionYearEducation?.label}
                  </li>
                </ul>
              ))}
            </div>
          </section>

          {props.professionalExperience[0] ? (
            <section className="professional-experience">
              <h2>EXPÊRIENCIA PROFISSIONAL</h2>

              <div>
                {professionalExperience.map((item, index) => (
                  <>
                    <ul key={index} className="resume-experiences">
                      <li>{item.companyPosition}</li>
                      <li>
                        {item.company} | {item.startYear.label} -{' '}
                        {isCurrentJob(item.currentJob, item)}
                      </li>
                    </ul>
                    {item.description && (
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    )}
                  </>
                ))}
              </div>
            </section>
          ) : null}

          <div className="page-break" />
        </div>

        <div className="page-footer">
          <img src={bizutlogo} alt="logo" />
        </div>
      </div>
    </div>
  );
});
