import React, { useCallback, useEffect, useContext, useMemo } from 'react';

import { PersonalDataEdit } from 'containers/ResumeForms/PersonalData';
import { LocalizationEdit } from 'containers/ResumeForms/Localization';
import AcademicEducation from 'containers/ResumeForms/AcademicEducation';
import ProfessionalExperience from 'containers/ResumeForms/ProfessionalExperience';

import { HeaderForm } from 'components/Header';
import Sidebar from 'components/Sidebar';
import './styles.scss';

import { StepContext } from 'contexts/StepContext';

import useLocalStorage from 'hooks/useLocalStorage';

export default function ResumeEdit() {
  const { currentStep } = useContext(StepContext);

  const sideBarObservation = useMemo(() => {
    if (currentStep.error) {
      return 'Atenção! Você esqueceu de preencher um dado importante';
    }

    if (currentStep.name === 'finish') {
      return 'Seu currículo já está pronto e prestes a ser concluído.';
    }

    return `Atenção! Não esqueça de preencher seus dados de forma correta
      para que nenhuma informação esteja errada.`;
  }, [currentStep]);
  const [storage] = useLocalStorage('@step-to-edit');

  const getComponentToEdit = useCallback(() => {
    switch (storage) {
      case '1':
        return <PersonalDataEdit />;
      case '2':
        return <LocalizationEdit />;
      case '3':
        return <AcademicEducation isChangeResume />;
      case '4':
        return <ProfessionalExperience isChangeResume />;
      default:
        return null;
    }
  }, [storage]);

  useEffect(() => {
    return () => localStorage.removeItem('@step-to-edit');
  }, []);

  return (
    <div className="container-edit">
      <HeaderForm />
      <Sidebar
        title={currentStep?.title}
        observation={sideBarObservation}
        supportIsVisible
      />
      {getComponentToEdit()}
    </div>
  );
}
