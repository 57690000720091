/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { Label } from 'reactstrap';
import FormGroup from 'components/FormGroup';

import loaderIcon from 'assets/animations/input-loader.svg';
import './styles.scss';

export default function InputSearch({
  label,
  fieldName,
  value,
  onChange,
  placeholder,
  errors,
  touched,
  data,
  className,
  onKeyUp,
  isLoading,
}) {
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (val) => {
    if (fieldName === 'courseName') {
      onChange(fieldName, { Curso: { nome: val.target.value, id: 9999 } });
    }

    if (fieldName === 'institutionName') {
      onChange(fieldName, {
        Instituicao: { nome: val.target.value, id: 9999 },
      });
    }

    setIsVisible(true);
  };

  const handleSelectItem = (item) => {
    setIsVisible(false);
    onChange(fieldName, item);
  };

  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <FormGroup className={className}>
      <Label style={{ fontSize: '0.75rem' }}>{label}</Label>
      <input
        ref={inputRef}
        placeholder={placeholder}
        className="form-control"
        name={fieldName}
        value={value}
        onChange={handleChange}
        onKeyUp={onKeyUp}
      />

      {isVisible ? (
        <div
          ref={wrapperRef}
          className={`input-search ${
            !isVisible || (data.length === 0 && 'd-none')
          }`}
        >
          {data.map((item) => (
            <div
              onClick={() => handleSelectItem(item)}
              className="data-items"
              key={
                fieldName === 'courseName' ? item.Curso.id : item.Instituicao.id
              }
            >
              <span>
                {fieldName === 'courseName'
                  ? item.Curso.nome
                  : item.Instituicao.nome}
              </span>
            </div>
          ))}
        </div>
      ) : null}

      {errors && touched && (
        <div className="invalid-feedback d-block">{errors}</div>
      )}

      {isLoading && <img className="loader" src={loaderIcon} alt="loader" />}
    </FormGroup>
  );
}
