/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import { MdOutlineAdd } from 'react-icons/md';

import Button from 'components/Button';
import FormButtonList from 'components/FormButton/FormButtonList';
import useCanBackStep from 'hooks/useCanBackStep';
import FormTitle from 'components/FormTitle';
import professionalIcon from 'assets/img/steps/professional-icon.svg';

import ProfessionalExperienceItem from './ProfessionalExperienceItem';

export default function ProfessionalExperienceList({
  data,
  isCompleted,
  isChangeResume,
  onChangeEdit,
  onChangeRemove,
  onChangeNewExperience,
}) {
  const { handleCanBackStepStorage } = useCanBackStep();

  const handleNext = useCallback((goToNext, steps, step) => {
    if (steps.length - 1 <= steps.indexOf(step)) {
      return;
    }

    step.isDone = true;
    goToNext();
  }, []);

  const handlePrev = useCallback(
    (goToPrev, steps, step) => {
      if (steps.indexOf(step) <= 0) {
        return;
      }
      handleCanBackStepStorage();
      goToPrev();
    },
    [handleCanBackStepStorage]
  );

  return (
    <div className="av-tooltip tooltip-label-bottom content-form">
      <div className="content-row">
        <FormTitle icon={professionalIcon} title="Experiência Profissional" />

        <div className="experience-list">
          {data.map((item, index) => (
            <ProfessionalExperienceItem
              key={index}
              data={item}
              onChangeEdit={() => onChangeEdit(index)}
              onChangeRemove={() => onChangeRemove(index)}
            />
          ))}
        </div>
      </div>

      <div className="list-buttons">
        <Button
          type="button"
          label="Adicionar Experiência"
          color="primary"
          className={`add-mobile ${
            isChangeResume ? 'w-100 mr-2 mb-3' : 'w-100 my-3'
          }`}
          onClick={onChangeNewExperience}
          style={{ fontSize: isChangeResume && '0.75rem' }}
        />

        <div
          className="add-desktop d-flex align-items-center"
          role="button"
          tabIndex={0}
          onClick={onChangeNewExperience}
        >
          <span>Adicionar Experiência</span>
          <span className="icon">
            <MdOutlineAdd size={24} fill="#fff" />
          </span>
        </div>

        <FormButtonList
          isChangeResume={isChangeResume}
          onChangeNext={handleNext}
          onChangePrev={handlePrev}
          isCompleted={isCompleted}
        />
      </div>
    </div>
  );
}
