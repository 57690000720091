/* eslint-disable no-return-assign */
import React from 'react';
import { Label } from 'reactstrap';
import NumberFormat from 'react-number-format';
import FormGroup from 'components/FormGroup';
import loaderIcon from 'assets/animations/input-loader.svg';

export default function InputMasked({
  label,
  fieldName,
  value,
  onChange,
  placeholder,
  format,
  errors,
  touched,
  onKeyUp,
  isLoading,
}) {
  const handleChange = (val) => {
    const formatedValue = val.target.value.replace(/[^a-zA-Z0-9]+/g, '');
    onChange(fieldName, formatedValue);
  };

  return (
    <FormGroup>
      <Label style={{ fontSize: '0.75rem' }}>{label}</Label>
      <NumberFormat
        placeholder={placeholder}
        className="form-control"
        name={fieldName}
        value={value}
        format={format}
        mask=""
        onChange={handleChange}
        onKeyUp={onKeyUp}
      />
      {errors && touched && (
        <div className="invalid-feedback d-block">{errors}</div>
      )}

      {isLoading && <img className="loader" src={loaderIcon} alt="loader" />}
    </FormGroup>
  );
}
