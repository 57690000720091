import { useEffect, useState } from 'react';
import useLocalStorage from './useLocalStorage';

export default function useZendesk() {
  const [storage, setStorage] = useLocalStorage('@zendesk-opened', '');
  const [hasUnreadMessages, setUnreadMessages] = useState(false);

  function handleOpenZendesk(e) {
    e.preventDefault();

    window.zE('messenger', 'open');
    setStorage(true);
  }

  useEffect(() => {
    window.zE('messenger:on', 'unreadMessages', (count) => {
      if (count > 0) {
        setUnreadMessages(true);
      } else {
        setUnreadMessages(false);
      }
    });
  }, [storage]);

  return { handleOpenZendesk, hasUnreadMessages };
}
