/* eslint-disable react/no-array-index-key */
import React, { forwardRef } from 'react';
import person from 'assets/img/pdf/person.png';
import bizutlogo from 'assets/logos/logo_bizut.png';

import { getInitialsFullname, formatDate } from 'helpers/Utils';

export default forwardRef((props, ref) => {
  const { personalData, isPreview, localization } = props;

  const nameSplited = getInitialsFullname(
    personalData ? personalData.fullname : ''
  );

  function isCurrentJob(currentJob, item) {
    if (!currentJob) {
      return `${item.conclusionMonth?.label} de ${item.conclusionYearExperience?.label}`;
    }

    return 'o momento';
  }

  return (
    <div id="preview">
      <div
        ref={ref}
        className={`example2-container ${
          isPreview ? 'example2-container-preview' : 'example2-container-pdf'
        }`}
      >
        <div className="shape" style={{ height: '500px', overflow: 'hidden' }}>
          <svg
            viewBox="00 10 500 150"
            preserveAspectRatio="none"
            style={{ height: '100%', width: '100%' }}
          >
            <path
              d="M-4.22,156.42 C207.95,148.53 161.68,23.20 500.27,1.50 L326.46,-1.45 L0.00,0.00 Z"
              style={{ stroke: '100%', fill: props.color }}
            />
          </svg>
        </div>

        <div className="example2-info-candidate">
          <h2 className="uppercase">{nameSplited[0]}</h2>
          {nameSplited[1] && <h2 className="uppercase">{nameSplited[1]}</h2>}

          <div className="divisor" />

          {localization && (
            <ul className="address">
              <li>
                {localization.state.Estado.nome} -{' '}
                {localization.city.Cidade.nome}
              </li>
              <li className="street">
                {localization.street}, {localization.number} <br />
                {localization.complement ? `${localization.complement}` : ''}
              </li>
            </ul>
          )}
        </div>

        <div className="example2-image-candidate">
          <img
            src={props.image || person}
            alt="person"
            className="img-rounded"
          />
        </div>

        <div className="example2-resume-content">
          <div className="d-flex justify-content-between">
            <div className="left-content">
              <h2 className="content-title" style={{ color: props.color }}>
                Informações de Contato
              </h2>
              <ul>
                <li>{personalData.email}</li>
                <li>{personalData.phone}</li>
                <li>{personalData.maritalStatus.label}</li>
                <li>{personalData.nacionality}</li>
                {personalData.pcd.value === 0 ? null : (
                  <li>{personalData.pcd.label}</li>
                )}
                <li>{formatDate(personalData.bornDate)}</li>
              </ul>
            </div>

            <div className="right-content">
              <h2 className="content-title" style={{ color: props.color }}>
                Formação Acadêmica
              </h2>

              <div>
                {props.academicEducation.map((item, index) => (
                  <ul className="resume-academic" key={index}>
                    <li>{item.institutionName.Instituicao.nome}</li>
                    {item.formationLevel.value > 4 &&
                      item.formationLevel.value < 5 && (
                        <li>{item.courseName.Curso.nome}</li>
                      )}
                    <li>{item.formationLevel.label}</li>
                    <li>
                      {item.startMonth.label} de {item.startYear.label} -{' '}
                      {item.conclusionMonth.label} de{' '}
                      {item.conclusionYearEducation.label}
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          </div>

          <div className="center-content">
            {props.professionalExperience[0] ? (
              <div className="professional-experience">
                <h2 className="content-title" style={{ color: props.color }}>
                  Experiência Profissional
                </h2>

                <div>
                  {props.professionalExperience.map((item, index) => (
                    <React.Fragment key={index}>
                      <ul className="resume-experiences">
                        <li>{item.company}</li>
                        <li>{item.companyPosition}</li>
                        <li>
                          {item.startMonth?.label} de {item.startYear?.label} -{' '}
                          {isCurrentJob(item.currentJob, item)}
                        </li>
                      </ul>
                      {item.description && (
                        <div
                          className="description"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="page-footer">
          <img src={bizutlogo} alt="logo" />
        </div>
        <div className="page-break" />
      </div>
    </div>
  );
});
