import * as Yup from 'yup';

export default Yup.object().shape({
  country: Yup.object().required('Campo obrigatório'),
  state: Yup.object().required('Campo obrigatório'),
  city: Yup.object().required('Campo obrigatório'),
  cep: Yup.string().when('country', {
    is: (country) => country?.Pais.id === '76',
    then: Yup.string(),
  }),
  street: Yup.string().required('Campo obrigatório'),
  neighborhood: Yup.string().required('Campo obrigatório'),
  number: Yup.string().required('Campo obrigatório'),
  complement: Yup.string().max(50, 'Máximo de 50 caracteres'),
});
