import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import useLocalStorage from 'hooks/useLocalStorage';
import { HashContext } from 'contexts/HashContext';
import { StepContext } from 'contexts/StepContext';
import { getMonthFromString } from 'helpers/Utils';

import ProfessionalExperienceForm from 'containers/ResumeForms/ProfessionalExperience/ProfessionalExperienceForm';
import ProfessionalExperienceList from 'containers/ResumeForms/ProfessionalExperience/ProfessionalExperienceList';

function orderExperienceByDate(experiences) {
  return experiences.sort((a, b) => {
    const formatedDateA = `${a.startYear.value}-${getMonthFromString(
      a.startMonth.value
    )}-01`;

    const formatedDateB = `${b.startYear.value}-${getMonthFromString(
      b.startMonth.value
    )}-01`;

    return new Date(formatedDateB) - new Date(formatedDateA);
  });
}

export default function ProfessionalExperience({ isChangeResume = false }) {
  const { hash } = useContext(HashContext);
  const { setCurrentStep } = useContext(StepContext);

  const history = useHistory();
  const [storage, setLocalStorage] = useLocalStorage('@step-four');
  const [professionalExperienceValues, setProfessionalExperienceValues] =
    useState(storage || []);
  const [showProfessionalExperienceList, setShowProfessionalExperienceList] =
    useState(true);
  const [valuesToEdit, setValuesToEdit] = useState({}); // initial value when edit academic education
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [allowNewExperience, setAllowNewExperience] = useState(false);

  const initialValues = useMemo(() => {
    return {
      hasProfessionalExperience: '',
      company: '',
      companyPosition: '',
      startMonth: '',
      startYear: '',
      currentJob: false,
      conclusionMonth: '',
      conclusionYearExperience: '',
    };
  }, []);

  /*
    After any action in form, need call this function to remove form and 
    show list of experiences
  */
  function leaveForm() {
    setShowProfessionalExperienceList(true);
    setValuesToEdit({});
    setAllowNewExperience(false);
    setCurrentIndex(-1);
  }

  const handleEditFromStorage = useCallback(
    (index) => {
      setCurrentIndex(index);
      setValuesToEdit(professionalExperienceValues[index]);
      setShowProfessionalExperienceList(false);
    },
    [professionalExperienceValues]
  );

  const handleRemoveFromStorage = useCallback((indexValue) => {
    setProfessionalExperienceValues((prevState) =>
      prevState.filter((item, index) => index !== indexValue)
    );
  }, []);

  const handleSaveValuesToStorage = useCallback(
    (val) => {
      const result = professionalExperienceValues.find(
        (item, index) => index === currentIndex
      );

      if (result) {
        professionalExperienceValues[currentIndex] = val;
        const order = orderExperienceByDate(professionalExperienceValues);
        setProfessionalExperienceValues(order);
        leaveForm();
        return;
      }

      if (val.hasProfessionalExperience.value === 'not') {
        history.replace(`/visualizar/${hash}`, null);
      }

      setProfessionalExperienceValues((prevState) => [...prevState, val]);
      leaveForm();
    },
    [professionalExperienceValues, currentIndex, history, hash]
  );

  const handleCancel = useCallback(() => {
    if (Object.values(professionalExperienceValues).length === 0) {
      history.replace(`/visualizar/${hash}`, null);
    }

    leaveForm();
  }, [hash, history, professionalExperienceValues]);

  const showHasExperienceSelect = useMemo(() => {
    return (
      (!allowNewExperience ||
        !Object.values(professionalExperienceValues).length > 0) &&
      !Object.keys(valuesToEdit).length > 0
    );
  }, [allowNewExperience, valuesToEdit, professionalExperienceValues]);

  useEffect(() => {
    if (Object.keys(professionalExperienceValues).length === 0) {
      setShowProfessionalExperienceList(false);
    }

    const order = orderExperienceByDate(professionalExperienceValues);
    setLocalStorage(order);
  }, [professionalExperienceValues, setLocalStorage]);

  useEffect(() => {
    setCurrentStep({
      name: 'professionalExperience',
      title: 'Experiência Profissional',
    });
  }, [setCurrentStep]);

  React.useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    window._loq?.push(['tag', 'Experiencia Profissional', true, true]);
  }, []);

  return (
    <div
      className={`resume-form-container ${isChangeResume ? 'form-edit' : ''}`}
    >
      {!showProfessionalExperienceList ||
      Object.keys(valuesToEdit).length > 0 ||
      allowNewExperience ? (
        <ProfessionalExperienceForm
          isEdit={isChangeResume}
          showHasExperienceSelect={showHasExperienceSelect}
          onChangeProfessionalExperienceToStorage={handleSaveValuesToStorage}
          onCancel={handleCancel}
          allowBackStep={
            !Object.values(professionalExperienceValues).length > 0
          }
          initialValues={
            Object.keys(valuesToEdit).length > 0 ? valuesToEdit : initialValues
          }
        />
      ) : (
        <ProfessionalExperienceList
          data={professionalExperienceValues}
          isChangeResume={isChangeResume}
          onChangeEdit={(index) => handleEditFromStorage(index)}
          onChangeRemove={(index) => handleRemoveFromStorage(index)}
          onChangeNewExperience={() => setAllowNewExperience(true)}
        />
      )}
    </div>
  );
}
