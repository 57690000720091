import React, { useCallback } from 'react';
import SwitchButton from 'rc-switch';
import 'rc-switch/assets/index.css';

export default function Switch({ name, onChange, onBlur, value }) {
  const handleChange = useCallback(
    (val) => {
      onBlur(name, true);
      onChange(name, val);
    },
    [name, onBlur, onChange]
  );

  return (
    <SwitchButton
      name="switch"
      className="custom-switch custom-switch-primary"
      value={value}
      checked={value}
      onChange={handleChange}
    />
  );
}
