import React from 'react';
import logo from 'assets/logos/logo_bizut.png';
import './styles.scss';

export default function Header() {
  return (
    <div className="form-header img-fluid d-flex align-items-center justify-content-center py-3">
      <img src={logo} height={24} alt="alt" />
    </div>
  );
}
