import React, { createRef, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { HashContext } from 'contexts/HashContext';

import useLocalStorage from 'hooks/useLocalStorage';
import LocalizationForm from './LocalizationForm';

export default function Localization() {
  const { hash } = useContext(HashContext);
  const form = createRef(null);
  const history = useHistory();
  const [storageValues, setStorageValues] = useLocalStorage('@step-two');

  const initialValues = useMemo(() => {
    return storageValues;
  }, [storageValues]);

  function handleSave() {
    setStorageValues(form.current.values);
    history.replace(`/visualizar/${hash}`, null);
  }

  function handleCancel() {
    history.replace(`/visualizar/${hash}`, null);
  }

  return (
    <div className="form-edit">
      <LocalizationForm
        form={form}
        onChangeSave={handleSave}
        onChangeCancel={handleCancel}
        isEdit
        initialValues={initialValues}
      />
    </div>
  );
}
