import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
import useCanBackStep from 'hooks/useCanBackStep';
import { StepContext } from 'contexts/StepContext';

import AcademicEducationForm from 'containers/ResumeForms/AcademicEducation/AcademicEducationForm';
import AcademicEducationList from 'containers/ResumeForms/AcademicEducation/AcademicEducationList';

export default function AcademicEducation({ isChangeResume = false }) {
  const { handleCanBackStepStorage } = useCanBackStep();
  const { setCurrentStep } = useContext(StepContext);

  const [storage, setLocalStorage] = useLocalStorage('@step-three');
  const [stepIsCompleted, setStepCompleted] = useLocalStorage(
    '@step-three-completed',
    false
  );
  const [canBack] = useLocalStorage('@can-back');
  const [academicEducationValues, setAcademicEducationValues] = useState(
    storage || []
  );
  const [showEducationList, setShowEducationList] = useState(true);
  const [currentFormValue, setCurrentFormValue] = useState({}); // initial value when edit academic education
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [allowNewFormation, setAllowNewFormation] = useState(false);
  const [isCompleted, setCompleted] = useState(false);

  const initialValues = useMemo(() => {
    return {
      courseName: '',
      institutionName: '',
      formationLevel: '',
      currentSituation: '',
      startMonth: '',
      startYear: '',
      conclusionMonth: '',
      conclusionYearEducation: '',
    };
  }, []);

  useEffect(() => {
    if (academicEducationValues.length === 0) {
      setStepCompleted(false);
    }
  }, [academicEducationValues, setStepCompleted]);

  useEffect(() => {
    if (canBack) {
      return;
    }

    if (stepIsCompleted) {
      setCompleted(true);
    }
  }, [canBack, stepIsCompleted]);

  /* 
    After any action in form, need call this function to remove form and 
    show list of formations
  */
  function leaveForm() {
    setShowEducationList(true);
    setCurrentFormValue({});
    setAllowNewFormation(false);
    setCurrentIndex(-1);
  }

  const handleNext = useCallback(
    (goToNext, steps, step) => {
      if (steps.length - 1 <= steps.indexOf(step)) {
        return;
      }

      setStepCompleted(true);

      step.isDone = true;
      goToNext();
    },
    [setStepCompleted]
  );

  const handlePrev = useCallback(
    (goToPrev, steps, step) => {
      if (steps.indexOf(step) <= 0) {
        return;
      }
      handleCanBackStepStorage();
      goToPrev();
    },
    [handleCanBackStepStorage]
  );

  const handleEdit = useCallback(
    (index) => {
      setCurrentIndex(index);
      setCurrentFormValue(academicEducationValues[index]);
      setShowEducationList(false);
    },
    [academicEducationValues]
  );

  const handleRemove = useCallback((indexValue) => {
    setAcademicEducationValues((prevState) =>
      prevState.filter((item, index) => index !== indexValue)
    );
  }, []);

  const handleSaveValues = useCallback(
    (val) => {
      const result = academicEducationValues.find(
        (item, index) => index === currentIndex
      );

      if (result) {
        academicEducationValues[currentIndex] = val;
        leaveForm();
        return;
      }

      setAcademicEducationValues((prevState) => [...prevState, val]);
      leaveForm();
    },
    [academicEducationValues, currentIndex]
  );

  const handleNewFormation = useCallback(() => {
    setAllowNewFormation(true);
  }, []);

  useEffect(() => {
    if (Object.keys(academicEducationValues).length === 0) {
      setShowEducationList(false);
    }

    setLocalStorage(academicEducationValues);
  }, [academicEducationValues, setLocalStorage]);

  useEffect(() => {
    setCurrentStep({
      name: 'academicEducation',
      title: 'Formação Acadêmica',
    });
  }, [setCurrentStep]);

  return (
    <div
      className={`resume-form-container ${isChangeResume ? 'form-edit' : ''}`}
    >
      {!showEducationList ||
      Object.keys(currentFormValue).length > 0 ||
      allowNewFormation ? (
        <AcademicEducationForm
          isChangeResume={isChangeResume}
          canCancel={Object.values(academicEducationValues).length > 0}
          onChangeAcademicEducation={handleSaveValues}
          onCancel={leaveForm}
          allowBackStep={!Object.values(academicEducationValues).length > 0}
          initialValues={
            Object.keys(currentFormValue).length > 0
              ? currentFormValue
              : initialValues
          }
        />
      ) : (
        <AcademicEducationList
          isChangeResume={isChangeResume}
          data={academicEducationValues}
          isCompleted={isCompleted}
          onChangeEdit={(index) => handleEdit(index)}
          onChangeRemove={(index) => handleRemove(index)}
          onChangeNewFormation={handleNewFormation}
          onChangePrev={handlePrev}
          onChangeNext={handleNext}
        />
      )}
    </div>
  );
}
