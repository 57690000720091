import React, { useContext, useMemo } from 'react';
import { Wizard, Steps, Step } from 'react-albus';

import TopNavigation from 'components/wizard/TopNavigation';

import { PersonalDataRegister } from 'containers/ResumeForms/PersonalData';
import { LocalizationRegister } from 'containers/ResumeForms/Localization';
import AcademicEducation from 'containers/ResumeForms/AcademicEducation';
import ProfessionalExperience from 'containers/ResumeForms/ProfessionalExperience';
import Finish from 'containers/ResumeForms/Finish';

import { HeaderForm } from 'components/Header';
import Sidebar from 'components/Sidebar';

import { StepContext } from 'contexts/StepContext';

import './styles.scss';

const ResumeRegister = () => {
  const { currentStep } = useContext(StepContext);

  const sideBarObservation = useMemo(() => {
    if (currentStep.error) {
      return 'Atenção! Você esqueceu de preencher um dado importante';
    }

    if (currentStep.name === 'finish') {
      return 'Seu currículo já está pronto e prestes a ser concluído.';
    }

    if (currentStep.name === 'personalData') {
      return 'Essa é a primeira parte do seu currículo e reúne todos os seus dados pessoais e formas de contato com a empresa, por isso tenha certeza que os dados estão corretos.';
    }

    if (currentStep.name === 'localization') {
      return 'Informar o seu endereço é essencial para um currículo mais completo. Muitas empresas se preocupam com o bem estar dos seus colaboradores e observam o tempo gasto no deslocamento até o local de trabalho.';
    }

    if (currentStep.name === 'academicEducation') {
      return 'Não é necessário apresentar todo um histórico escolar, mas as formações mais recentes e relevantes como a sua graduação e pós na área. Caso não tenha formação superior, então não deixe de informar sua escolaridade de nível médio ou de ensino fundamental.';
    }

    if (currentStep.name === 'professionalExperience') {
      return 'Você deve compartilhar suas experiências, falar sobre suas atividades e resultados em cada cargo que ocupou. Não se preocupe se você ainda não tem uma experiência para relatar, o importante é explicar tudo que fez e mostrar do que é capaz.';
    }

    return '';
  }, [currentStep]);

  return (
    <div className="container-register">
      <HeaderForm />
      <Sidebar
        title={currentStep?.title}
        observation={sideBarObservation}
        supportIsVisible
      />
      <div className="wizard wizard-default card-body bg-white">
        <Wizard>
          <TopNavigation className="justify-content-center" disableNav />
          <Steps>
            <Step id="step1" name="Dados Pessoais">
              <PersonalDataRegister />
            </Step>
            <Step id="step2" name="Endereço">
              <LocalizationRegister />
            </Step>
            <Step id="step3" name="Formação Acadêmica">
              <AcademicEducation />
            </Step>
            <Step id="step4" name="Experiência Profissional">
              <ProfessionalExperience />
            </Step>
            <Step id="step5" hideTopNav>
              <Finish />
            </Step>
          </Steps>
        </Wizard>
      </div>
    </div>
  );
};

export default ResumeRegister;
