import React from 'react';
import SmallSide from 'components/Sidebar/SmallSide';
import { HeaderView } from 'components/Header';

import './styles.scss';

export default function Error() {
  return (
    <div className="error-container">
      <SmallSide />
      <div className="error-content">
        <HeaderView />
        <div className="error-texts">
          <h1>404</h1>
          <div>
            {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
            <h2>Página não encontrada {':('}</h2>
            <span>
              A página que você tentou acessar está indísponível ou não existe .
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
