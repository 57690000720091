/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Button from 'components/Button';
import React, { useState, useEffect } from 'react';

import './styles.scss';

export default function Bizut({
  mainPrivacyTerm = true,
  onAcceptTerm,
  onScroll = null,
  footerRef,
}) {
  const [isSelected, setSelected] = useState(false);
  const [vibrateBox, setVibrateBox] = useState(false);

  useEffect(() => {
    if (!vibrateBox) return;

    const timer = setTimeout(() => {
      setVibrateBox(false);
    }, 3000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [vibrateBox]);

  return (
    <div className={mainPrivacyTerm ? 'privacy-container' : 'privacy-continue'}>
      <div className="privacy__logo">
        <img
          src="https://i.ibb.co/svt7LDt/logo-bizut.png"
          width="150px"
          alt="logo"
        />
      </div>
      <div className="privacy__content">
        <h1 className="text-center mx-5" style={{ lineHeight: '2rem' }}>
          TERMO DE USO E POLÍTICA DE PRIVACIDADE DO BIZUT,
          <br />
          SISTEMA DE RECRUTAMENTO E SELEÇÃO E SEUS SITES BIZUT.COM.BR E BIZUT.ME
        </h1>
        {mainPrivacyTerm && onScroll && (
          <div
            style={{
              width: '100%',
              margin: '0.8rem 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={onScroll}
              label={
                <>
                  <span className="mr-1">Ir para o final</span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2.91659L7 11.0833"
                      stroke="white"
                      strokeWidth="1.16667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.0833 6.99992L7 11.0833L2.91666 6.99992"
                      stroke="white"
                      strokeWidth="1.16667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </>
              }
              style={{
                borderRadius: '.4rem',
              }}
            />
          </div>
        )}
        <p>
          O presente documento é dividido em duas partes, a primeira se refere
          ao termo de uso do Sistema de Recrutamento e Seleção BIZUT, e a
          segunda parte se refere a sua Política de Privacidade.
        </p>
        <p>
          O presente documento é indivisível, não sendo possível o aceite
          parcial. É importante que o candidato entenda os termos desse
          documento, concorde e dê o seu aceite para continuar a usar o sistema.
        </p>

        <h2>PARTE I - DO TERMO DE USO</h2>
        <p>
          Nós do BIZUT, entendemos que é importante que você, CANDIDATO, conheça
          funcionamento de nosso sistema de recrutamento e seleção que é
          utilizado para a gestão e monitoramento de vagas abertas por empresas
          clientes, parceiras e anunciantes. Dessa forma, estes Termos de Uso,
          em conjunto à Política de Privacidade, regem o uso do BIZUT, Sistema
          de Recrutamento e Seleção, em seus sites www.bizut.com.br e
          www.bizut.me, além de páginas de carreira integradas aos sites de
          empresas clientes, parceiras e anunciantes, mensagens de comunicação e
          feedback sobre as vagas e processos seletivos e ainda, aplicativos
          para uso mobile.
        </p>
        <p>
          Se após ler nossos Termos de Uso, caso você não entenda ou não
          concorde com algum ponto, pedimos que entre em contato conosco antes
          de continuar com o seu cadastro.
        </p>
        <p>
          É imprescindível que você tenha ciência e esteja de acordo com todos
          os aspectos do sistema e seus Termos de Uso.
        </p>

        <h2> 1. DA ADESÃO </h2>
        <p>
          No momento do cadastro, você deverá ler, compreender e aceitar estes
          Termos de Uso, conforme opção específica disponibilizada no
          formulário. Caso não concorde com seus termos, você deverá deixar de
          utilizar o site e não realizar o cadastro.
        </p>
        <p>
          Este documento, em conjunto com a Política de Privacidade, têm
          natureza de Contrato de Adesão e passam por revisões periódicas, sem
          que seja necessária a sua notificação prévia. Por isso, é importante
          que você consulte os Termos de Uso e a Política de Privacidade para
          saber se continua concordando com seus termos antes de seguir com a
          navegação.
        </p>

        <h2>2. USO DO SITE</h2>
        <p>
          O Sistema de Recrutamento e Seleção é acessível por meio dos seus
          sites www.bizut.com.br e www.bizut.me, e permite aos usuários e
          candidatos o cadastro de currículo, cadastro de documentos e
          informações necessárias para admissão, o acompanhamento e
          monitoramento de vagas e a candidatura em processos seletivos ou vagas
          divulgadas por empresas clientes, parceiras e anunciantes.
        </p>
        <p>
          Cabe observar que a manutenção e funcionamento do sistema e seus
          serviços vinculados, são de responsabilidade do BIZUT. Fica observado
          que a equipe do Bizut trabalha para garantir o melhor funcionamento de
          seu sistema, prezando pelas melhores práticas de mercado e para manter
          e preservar o funcionamento e ainda, implementar melhorias e
          atualizações constantes e necessárias para oferecer sempre o melhor
          serviço possível. É importante destacar porém, que falhas são
          passíveis de ocorrer e que, na medida em as mesmas sejam
          identificadas, sempre caberá ao BIZUT sanar eventuais falhas
          identificadas para garantir o funcionamento do sistema.
        </p>
        <p>
          Você CANDIDATO se compromete a acessar e utilizar o site e sistema
          exclusivamente para fins legais lícitos, observando as boas práticas e
          jurisprudência do Direito, além da ética e boa fé no uso da internet.
          O CANDIDATO concorda em agir de acordo com as regras de uso do site e
          sistema, respondendo integralmente, como único responsável, pelas
          informações que veicular através de seu currículo e de seus dados
          cadastrais.
        </p>
        <p>
          É de exclusiva responsabilidade e ônus providenciar os equipamentos de
          informática e a conexão à Internet necessários para o acesso ao site e
          sistema, ficando o BIZUT isento de qualquer responsabilidade pela
          existência, funcionamento e qualidade de tais equipamentos e conexões.
        </p>

        <h2>3. DA GRATUIDADE DA PLATAFORMA E OFERTA DE SERVIÇOS PAGOS</h2>
        <p>
          A navegação, o cadastramento de currículo, a utilização das
          ferramentas disponíveis para busca e monitoramento de vagas abertas
          por empresas clientes, parceiras e anunciantes e a candidatura a
          processos seletivos à partir dos sites são GRATUITAS, podendo o
          candidato manter seu currículo ativo pelo período que desejar.
        </p>
        <p>
          Fica facultado ao BIZUT, remover qualquer currículo que não esteja de
          acordo com à legislação vigente (informações falsas, currículos
          fictícios, currículo feito por terceiros, etc), ou ainda, aqueles que
          não estejam de acordo com as políticas de uso do BIZUT.
        </p>
        <p>
          Mediante prévio e claro aviso, o BIZUT poderá oferecer serviços e
          utilidades específicas mediante remuneração ou pagamento, o que
          dependerá sempre de prévia e expressa anuência e contratação pelo
          CANDIDATO, respeitando sua livre escolha.
        </p>

        <h2>4. DO CADASTRO E ACESSO A CURRÍCULOS</h2>
        <p>
          Embora seja possível a navegação limitada no site, o acesso a todas as
          funcionalidades da plataforma, inclusive cadastro de currículo,
          documentos e candidatura em processos seletivos, depende da realização
          de cadastro.
        </p>
        <p>Você CANDIDATO não poderá utilizar os serviços do site caso:</p>
        <ol>
          <li>
            Não tenha idade mínima legal de 14 (quatorze) anos, para oferecer
            serviços como profissional.
          </li>
          <li>
            Não esteja em pleno gozo de sua capacidade para praticar atos na
            vida civil.
          </li>
          <li>
            As leis locais de seu país não permitam a utilização de serviços
            como os do site, por qualquer razão.
          </li>
        </ol>

        <p>
          No caso de utilização do site por menores de idade - nos casos
          permitidos em lei - isso apenas ocorre mediante autorização do
          responsável legal do candidato, que se responsabiliza pelo
          preenchimento do cadastro.
        </p>
        <p>
          O acesso aos seus dados pessoais, currículo e documentos cadastrados
          no sistema serão protegidos nos termos da legislação de proteção de
          dados aplicável, aí considerada a Lei 13.709/2018 (Lei Geral de
          Proteção de Dados) e da Política de Privacidade.
        </p>
        <p>É importante que você, CANDIDATO, saiba, contudo, que:</p>
        <ol>
          <li>
            As informações inseridas por você no seu currículo serão divulgadas
            no site PARA AS EMPRESAS CLIENTES, PARCEIRAS E ANUNCIANTES.
          </li>
          <li>
            O cadastro de seu currículo e documentos em processos de seleção
            publicados nos sites implica na revelação de todas as informações
            apostas no currículo pelo próprio candidato para o anunciante da
            respectiva vaga.
          </li>
        </ol>

        <p>
          Tendo em vista que os dados são acessíveis para visualização e
          divulgação para empresas cadastradas na plataforma, você está ciente
          de que o seu currículo e demais informações inseridas no site poderão
          ser visualizadas por terceiros, inclusive por seu atual empregador.
        </p>
        <p>
          Dessa forma, ao escolher o BIZUT, você têm ciência e reconhece que há
          o risco de que o seu currículo e documentos sejam acessados por
          terceiros, de modo que o BIZUT não poderá ser responsabilizado, em
          nenhuma hipótese, por eventuais danos sofridos por você decorrentes da
          visualização de seu currículo no site por seu atual empregador ou por
          quaisquer terceiros.
        </p>

        <h2>4.1 DO DESATIVAMENTO DO CURRÍCULO</h2>
        <p>
          É possível que você peça o desativamento do seu currículo por meio de
          solicitação destinada ao SAC, pelo email: suporte@bizut.com.br, que
          será respondido em até 15 dias a contar da solicitação, conforme art.
          19, inciso II da Lei 13.709/2018 (Lei Geral de Proteção de Dados).
        </p>
        <p>
          Nesse caso, as informações que você compartilhou conosco serão, quando
          possível, tornadas anônimas e, caso seja impossível, essas informações
          deverão ser desativadas no sistema. Alguns dos seus dados pessoais,
          contudo, poderão ser mantidos de forma protegida em nossos bancos de
          dados para as finalidades previstas na nossa Política de Privacidade,
          como eventuais auditorias e registro histórico.
        </p>
        <p>
          Em caso de desativar as informações, o BIZUT garante que não enviará
          e-mails ou compartilhará informações de CANDIDATOS que optarem por
          tornar seu currículo inativo. Porém, é importante deixar claro que os
          dados compartilhados para participação em processos seletivos
          específicos estão sob controle da empresa cliente, parceira ou
          anunciante da vaga, aplicando-se, então, as disposições constantes de
          suas próprias Políticas de Privacidade.
        </p>
        <p>
          Em qualquer caso de as informações tornem-se inativas, o CANDIDATO
          fica ciente de que será inviabilizada a sua candidatura a novas vagas,
          ficando à cargo das empresas com as quais o CANDIDATO já faça parte de
          processos seletivos a continuidade dos processos de recrutamento com
          as informações anteriormente já compartilhadas com essas empresas.
        </p>

        <h2>5. SENHAS E SEGURANÇA</h2>
        <p>
          Ao se cadastrar, o CANDIDATO reconhece que sua senha de acesso ao
          sistema em seus sites é de uso pessoal e intransferível, não podendo
          fornecê-la a terceiros em nenhuma hipótese. O CANDIDATO deverá tomar
          todas as providências cabíveis para garantir sua confidencialidade,
          devendo, inclusive, efetuar o logoff para finalizar o acesso à sua
          página de serviços.
        </p>
        <p>
          O nível de segurança da senha, conforme combinação de letras, números
          e caracteres é de responsabilidade exclusiva do CANDIDATO, que ainda
          se compromete a responder integralmente por quaisquer consequências
          jurídicas decorrentes diretamente da perda ou extravio de sua senha,
          devido a um comportamento seu considerado doloso ou culposo -
          imprudência, negligência ou imperícia.
        </p>
        <p>
          A recuperação de senhas perdidas ou esquecidas dar-se-á conforme as
          normas de segurança divulgadas nos sites. É proibido o uso de qualquer
          dispositivo, software ou outro recurso que venha a interferir nas
          atividades e operações do BIZUT, tanto pelos sites quanto por outros
          sistemas ou bancos de dados. Caso seja identificada qualquer
          intromissão, tentativa, ou atividade que viole ou contrarie as leis de
          direito de propriedade intelectual e/ou as disposições estipuladas
          nestes Termos de Uso, na Política de Privacidade e/ou leis vigentes
          aplicáveis, aí incluída a LGPD, o responsável estará sujeito às
          sanções aplicáveis, estejam elas em lei ou previstas neste documento.
          O responsável deverá, também, indenizar por eventuais danos causados.
        </p>
        <p>
          O BIZUT não se responsabiliza pelos danos causados pelo mau uso do
          sistema ou pela não observância do usuário, quanto à segurança
          necessária na proteção de sua senha, em computadores, celulares e ou
          dispositivos similares de terceiros a que venha realizar o acesso.
        </p>

        <h2>6. PROCESSOS SELETIVOS E VAGAS ANUNCIADAS</h2>
        <p>
          O BIZUT atua tão somente como fornecedor de soluções que aproximam o
          CANDIDATO e as empresas clientes, parceiras e anunciantes, não
          realizando e nem tendo a prerrogativa legal de realizar monitoramento,
          fiscalização, edição ou aprovação do conteúdo das vagas divulgadas
          pelas empresas, ou sobre a comunicação entre as empresas contratantes
          e o CANDIDATO, figurando tão somente como intermediário que facilita a
          comunicação entre as partes.
        </p>
        <p>
          O BIZUT não se responsabiliza pelo conteúdo das vagas anunciadas,
          pelas comunicações estabelecidas entre as empresas e os candidatos,
          nem pela condução dos processos seletivos pelas empresas clientes,
          parceiras e anunciantes, que são as únicas responsáveis pelas vagas,
          comunicações e seleções que anunciarem e/ou conduzirem. Cabe
          exclusivamente ao CANDIDATO zelar por seus melhores interesses, motivo
          pelo qual reconhece e concorda expressamente que o BIZUT não poderá
          ser responsabilizado perante o candidato ou qualquer terceiro, por
          perdas e danos de qualquer espécie que venham a ser conhecidas em
          decorrência de tais negociações, sejam contratuais, extracontratuais
          ou de qualquer outra natureza.
        </p>
        <p>
          O BIZUT compromete-se a manter currículo e documentos de admissão
          cadastrados conforme as funcionalidades do site, não se
          responsabilizando, entretanto, pela participação efetiva do CANDIDATO
          em processos seletivos e suas etapas, incluindo entrevistas, e nem por
          sua contratação.
        </p>
        <p>
          O CANDIDATO tem ciência de que, com sua anuência, à partir do seu
          cadastro e aceitação dos Termos de Uso, poderão ser enviadas mensagens
          diversas para seu e-mail, incluindo e não somente, mensagens sobre
          vagas e processos seletivos abertos que estejam de acordo com o perfil
          cadastrado nos sites, tendo ocorrido a candidatura ou não.
        </p>

        <h2>7. RESPONSABILIDADES</h2>
        <p>
          O sistema é oferecido através de seus sites para uso ao CANDIDATO e, é
          por este aceito, no estado técnico e de perfeição em que se encontra,
          razão pela qual o BIZUT não responde por nenhuma outra garantia, como
          também não responde, entre outras hipóteses:
        </p>
        <ol>
          <li>
            Pela adequação dos sites às necessidades ou expectativas do
            candidato;
          </li>
          <li>
            Pelos resultados ou desempenhos esperados pelo candidato ao usar os
            sites;
          </li>
          <li>Por um uso ininterrupto e livre de erros dos sites;</li>
          <li>
            Por qualquer informação ou comunicação veiculada pelas empresas
            clientes, parceiras e anunciantes de vagas e processos seletivos;
          </li>
          <li>
            Pela correção e aperfeiçoamento dos erros encontrados pelo CANDIDATO
            nos sites.
          </li>
        </ol>

        <p>
          Em razão da própria natureza da Internet, existe o risco de que
          terceiros mal-intencionados acessem indevidamente as informações
          armazenadas nos nossos sistemas. Caso isso ocorra, o BIZUT se
          responsabiliza nos limites previstos na legislação vigente aplicável,
          sempre zelando pela segurança e integridade do sistema, fazendo todo o
          esforço possível, para manter segura as informações armazenadas no
          sistema.
        </p>
        <h2>8. INTERRUPÇÃO DO USO DO SITE OU DE CONTEÚDO DIVULGADO</h2>
        <p>
          O BIZUT poderá suspender ou interromper o funcionamento dos sites para
          fins de manutenção ou por caso fortuito ou de força maior,
          independentemente de qualquer aviso prévio.
        </p>
        <p>
          Caso o BIZUT tome ciência por si mesma, ou por qualquer terceiro, de
          que qualquer informação veiculada pelo CANDIDATO através do site está
          em desacordo com o sistema jurídico vigente, inclusive por conflito
          com direitos de terceiros, nós lhe enviaremos comunicação para que o
          CANDIDATO se manifeste sobre tais fatos no prazo máximo de 24 (vinte e
          quatro) horas, à fim de que, o CANDIDATO tenha o direito de se
          manifestar e, sendo o caso, apresentar sua justificativa.
        </p>
        <p>
          Se após a sua manifestação ficar constatada a ilicitude ou
          irregularidade do conteúdo publicado pelo CANDIDATO, o BIZUT poderá
          excluir a informação, ou até mesmo desativar seu cadastro dos sites
          unilateralmente, independentemente de sua autorização, podendo
          fornecer todas as informações às autoridades legais, nos termos da
          lei.
        </p>
        <p>
          Ainda, se o CANDIDATO não se manifestar no prazo designado acima, o
          BIZUT poderá suspender o conteúdo publicado unilateralmente,
          independentemente da autorização do CANDIDATO, até a devida
          regularização ou manifestação no sentido de demonstrar a adequação do
          conteúdo ao sistema jurídico vigente, sem prejuízo a resposta ou
          qualquer sanção de caráter administrativo.
        </p>
        <p>
          Caso se constate uma incontroversa ilicitude de conteúdo que possa
          causar danos de qualquer espécie a terceiros ou envolva legislação de
          natureza penal, o BIZUT poderá suspendê-lo, unilateralmente, antes de
          comunicar ao CANDIDATO para que se manifeste a respeito, nos termos do
          acima.
        </p>
        <p>
          A suspensão ou exclusão de conteúdo também será unilateral e
          independentemente de qualquer comunicação ao CANDIDATO quando
          determinado por órgão do poder público competente, ficando o BIZUT
          isento de quaisquer responsabilidades.
        </p>
        <p>
          A suspensão de conteúdo ajustada nesta cláusula não dará direito a
          nenhuma espécie de indenização, nem a reparação de danos ou prejuízos
          pelo BIZUT ao CANDIDATO ou terceiros
        </p>
        <p>
          O BIZUT se reserva ao direito de, uma vez constatado o descumprimento
          pelo candidato de qualquer obrigação decorrente dos presentes Termos
          de Uso ou da Política de Privacidade, desativar o acesso do CANDIDATO
          aos sites, ou mesmo, excluir o cadastro, currículo e documentos
          registrados no sistema, sem prejuízo da possibilidade de remessa de
          seus dados cadastrais e de acesso às autoridades legais competentes, e
          sem que isso represente lesão a seus direitos de privacidade.
        </p>

        <h2>9. TOLERENCIA</h2>
        <p>
          O não exercício, por qualquer das partes, de seus direitos ou
          prerrogativas legais ou contratuais, constituirá sempre mera
          liberalidade, não servindo de precedente para futuros descumprimentos,
          nem alteração ou extinção das obrigações contratualmente assumidas.
          Também não caracteriza, em nenhuma hipótese, novação, transação,
          compensação ou remissão, nem constitui hipóteses de
          surrectio/supressio, podendo tais direitos ser exercidos a qualquer
          tempo, quando conveniente para o seu titular, inclusive para a
          exigência de obrigações vencidas e não cumpridas.
        </p>

        <h2>10. E-MAIL E ARMAZENAMENTO DE E-MAILS</h2>
        <p>
          Por sua livre opção, o CANDIDATO poderá autorizar o BIZUT a enviar
          para o e-mail informado no cadastro toda e qualquer comunicação nossa
          ou proveniente de qualquer uma das empresas cadastradas no site, bem
          como boletins periódicos ou informativos do site, mesmo que estes
          contenham links de terceiros, sejam ou não estes parceiros comerciais
          do Bizut.
        </p>
        <p>
          O uso dos seus dados para essa finalidade está disposto na nossa
          Política de Privacidade.
        </p>

        <h2>11. PROTEÇÃO DE DADOS PESSOAIS DO CANDIDATO</h2>
        <p>
          Todas as disposições sobre coleta, uso e proteção dos seus dados
          pessoais estão dispostas na nossa Política de Privacidade.
        </p>
        <p>
          É importante que você saiba, contudo, que quando a utilização do
          ambiente do Bizut ocorrer via páginas de “Trabalhe Conosco” de
          empresas clientes, bem como quando os seus dados forem compartilhados
          com os anunciantes das vagas, também se aplicarão as Políticas de
          Privacidade específicas de cada um desses sites e empresas.
        </p>

        <h2>12. DIREITO DE PROPRIEDADE</h2>
        <p>
          O uso do nome Bizut, dos domínios www.bizut.com.br e www.bizut.me, bem
          como os conteúdos das telas do site são propriedade da empresa e estão
          protegidos pelas leis e tratados internacionais de propriedade
          intelectual. Você não deverá usar indevidamente ou reproduzir, total
          ou parcialmente, tais conteúdos, salvo quando houver autorização
          expressa.
        </p>
        <p>
          De forma semelhante, o uso dos nomes, domínios e demais informações
          das empresas clientes do Bizut, também está coberto pelas normas de
          propriedade industrial e intelectual, sendo proibido o uso indevido e
          a reprodução, total ou parcial, de tais conteúdos, salvo quando houver
          autorização expressa do titular desses direitos.
        </p>

        <h2>PARTE II - DA POLÍTICA DE PRIVACIDADE</h2>
        <p>
          A Política de Privacidade, em conjunto aos Termos de Uso, descreve o
          compromisso do Bizut em como utilizará e tratará os dados pessoais dos
          candidatos, seja através dos sites www.bizut.com.br e www.bizut.me,
          aplicativos para mobile ou no compartilhamento das informações
          pessoais com outros agentes de tratamento.
        </p>
        <p>
          Se após ler nossa política você não entender ou concordar com algum
          ponto, pedimos que entre em contato conosco antes de continuar com o
          seu cadastro
        </p>

        <h2>13. DADOS QUE SÃO PROTEGIDOS PELA LGPD</h2>
        <p>
          A Lei Geral de Proteção de Dados - LGPD (Lei nº 13.709/2018) veio para
          proteger os dados pessoais e dados pessoais sensíveis da pessoa física
          no Brasil, também chamada de titular de dados.
        </p>
        <p>
          De acordo com a LGPD, “dado pessoal” é qualquer dado que identifique
          ou torne identificável uma pessoa física. E o “dado pessoal sensível”
          é aquele que, por sua natureza, diz respeito a informações que merecem
          um cuidado maior, principalmente para proteção contra discriminações.
        </p>
        <p>
          A LGPD coloca nessa classificação de dados pessoais sensíveis dados
          sobre origem racial ou étnica, convicção religiosa, opinião política,
          filiação a sindicato ou a organização de caráter religioso, filosófico
          ou político, dado referente à saúde ou à vida sexual e dado genético
          ou biométrico, quando vinculados a uma pessoa física.
        </p>

        <h2>14. FINALIDADE E USO DOS SEUS DADOS PESSOAIS</h2>
        <p>
          O Bizut é um software que aproxima candidatos e empresas clientes. Os
          dados dos candidatos são disponibilizados para as empresas clientes
          apenas mediante contrato e para finalidade de recrutamento e seleção
          para vagas de trabalho.
        </p>
        <p>
          O Bizut não cobra dos candidatos nenhuma quantia para que estes
          compartilhem seus currículos com empresas clientes, anunciantes e
          autorizadas, nem permite que terceiros cobrem para que candidatos
          participem de processos seletivos abertos através da plataforma, essas
          operações são GRATUITAS.
        </p>
        <p>
          Desta forma, a remuneração do Bizut ocorrerá através da prestação de
          serviços para empresas autorizadas ou pela oferta de informações a
          anunciantes que tenham interesse em patrocinar seus produtos e
          serviços para os candidatos do Bizut.
        </p>
        <p>
          De uma forma mais detalhada, utilizamos as informações e os seus dados
          pessoais coletados para cumprir as seguintes finalidades:
        </p>
        <ol>
          <li>
            Viabilizar sua participação em processos seletivos de trabalho;
          </li>
          <li>
            Incluir seus dados no banco de talentos das empresas em cujos
            processos seletivos você se inscreveu;
          </li>
          <li>
            Entrar em contato com você, em razão de andamento do processo
            seletivo de que você esteja participando ou para informá-lo sobre
            novos processos que possam ser de seu interesse, incluindo o envio
            de alertas de vagas;
          </li>
          <li>
            Permitir que empresas busquem ativamente candidatos aderentes às
            vagas abertas dentro do banco de dados do Bizut de acordo com as
            política de privacidade e o termo de uso.
          </li>
          <li>
            De forma opcional, quando autorizado pelo por você, para o envio de
            informativos, comunicados institucionais e newsletter, inclusive
            sobre produtos e serviços oferecidos pelo Bizut e seus parceiros.
          </li>
        </ol>

        <p>
          No intuito de aproximar candidatos e empresas, o Bizut também usa
          dados pessoais dos usuários vinculados às empresas para permitir o
          acesso à plataforma e aos serviços contratados.
        </p>
        <p>
          Poderemos utilizar seus dados em conexão com nossos serviços para
          outras finalidades, mediante aviso prévio, ou ainda, para cumprir
          obrigações acessórias aos serviços já prestados.
        </p>

        <h2>
          15. DADOS PESSOAIS QUE COLETAMOS E QUE FORMAM O CURRÍCULO DOS
          CANDIDATOS
        </h2>
        <p>
          O acesso a todas as funcionalidades da plataforma Bizut, inclusive
          cadastro de currículo e candidatura a vagas anunciadas, depende do
          compartilhamento de informações pessoais e da realização de cadastro
          pelo candidato.
        </p>
        <p>
          Esse conjunto de dados que compõem o currículo pode conter os
          seguintes tipos de dados pessoais:
        </p>
        <ol>
          <li>
            Dados de identificação, de perfil, documentos pessoais e informações
            de contato;
          </li>
          <li>Dados necessários para elaboração de currículo profissional;</li>
          <li>Dados relativos a vagas inclusivas e para PCD;</li>
          <li>Foto profissional;</li>
          <li>
            Links para redes sociais, de relacionamento ou blogs do candidato;
          </li>
          <li>
            Objetivo profissional, inclusive para criação de alertas de vagas;
          </li>
          <li>
            Campos de anotações, testes e fichas complementares, preenchidos
            pelo candidato;
          </li>
          <li>Dados de geolocalização e de identificação do aparelho.</li>
        </ol>

        <p>
          Ao realizar o cadastro, é importante que você, ou a pessoa autorizada
          por você para preencher os seus dados pessoais, insira apenas
          informações verdadeiras e atualizadas, já que não nos
          responsabilizamos pela verificação. A responsabilidade, em qualquer
          caso, civil e/ou criminal, é sua quanto à veracidade, exatidão e
          autenticidade das informações incluídas em nosso cadastro.
        </p>
        <p>
          Além do currículo, nós coletamos e armazenamos automaticamente
          informações sobre a sua atividade e navegação no site, mediante a
          utilização de cookies.
        </p>
        <p>
          Se você for um adolescente tenha a certeza de ser assistido e obter a
          autorização de seus pais ou responsáveis antes de candidatar-se a uma
          vaga de emprego ou preencher seu currículo.
        </p>

        <h2>
          16. DADOS PESSOAIS NECESSÁRIOS PARA IDENTIFICAR OS USUÁRIOS (CLIENTES)
        </h2>
        <p>
          Nós também poderemos armazenar seu nome, e-mail, telefone de contato e
          documentos de identificação para permitir e vincular o seu acesso aos
          serviços prestados pelo Bizut aos seus clientes e novos controladores.
        </p>
        <p>
          Neste caso seus dados de usuário serão utilizados somente para
          garantir uma navegação segura e o acesso correto aos produtos e
          serviços contratados, sem compartilhamento com outros controladores.
          Os dados serão anonimizados a fim de resguardar a privacidade, não
          sendo possível a anonimização, os dados serão excluídos do sistema.
        </p>

        <h2>
          17. DO TRATAMENTO DE DADOS PESSOAIS E COMPARTILHAMENTO COM NOVOS
          CONTROLADORES
        </h2>
        <p>
          O Bizut será o controlador dos seus dados pessoais, quando inseridos
          através dos sites www.bizut.com.br e www.bizut.me ou captados por um
          de seus sistemas. Nesses casos, é nossa responsabilidade eleger
          adequadamente as bases legais condizentes com as finalidades previstas
          na presente política, bem como atender de forma direta às suas
          solicitações quanto aos direitos previstos na LGPD.
        </p>
        <p>
          Seus dados poderão ser compartilhados com novos controladores e
          empresas autorizadas pelo Bizut a utilizarem seus sistemas ou
          acessarem seus bancos de dados, sempre e especificamente no contexto
          de recrutamento e seleção ou para comunicação, somente pelo Bizut, de
          oferta de produtos e serviços que possam ser de interesse ou para
          candidatos.
        </p>
        <p>
          Os novos controladores, tais como empresas que anunciam vagas de
          empresa, consultorias de recrutamento, bem como empresas que queiram
          realizar triagens no banco de candidatos do Bizut, poderão importar ou
          obter seus dados, tendo autonomia para se comunicarem diretamente com
          você para a condução do processo seletivo ou para convidá-lo a se
          inscrever em outras vagas e para temas relacionados.
        </p>
        <p>
          O resultado final para contratação é de ÚNICA RESPONSABILIDADE das
          empresas autorizadas. O Bizut não tem ingerência sobre a condução e
          decisão dos processos seletivos ou sobre como novos controladores de
          dados utilizam as referidas informações para além de seus sistemas e
          plataformas.
        </p>
        <p>
          Nós também podemos compartilhar seus dados pessoais com outras
          empresas que contribuam para melhorar ou facilitar as operações
          através do site, como por exemplo: ferramentas de gerenciamento de
          bancos de dados, gerenciamento de emails, fluxos internos e de
          atendimento aos usuários, dentre outros. O Bizut se compromete a
          contratar apenas fornecedores que cumpram a legislação protetiva de
          dados, mediante a assinatura de acordos ou convênios com cláusulas
          específicas para isso.
        </p>
        <p>
          Em casos em que haja a necessidade de transferência internacional de
          dados pessoais para outros países, o Bizut se compromete a contratar
          apenas fornecedores e clientes que adotem medidas de segurança e boas
          práticas compatíveis com o nível de proteção estabelecido pela
          legislação brasileira, conforme regulamentação pela Autoridade
          Nacional de Dados Pessoais (ANPD). Na falta de regulamentação
          específica, nos comprometemos a trabalhar somente com empresas
          aderentes às demais normas de proteção de dados, como a GDPR europeia
          ou CCPA americana; ou com empresas cujos contratos contenham cláusulas
          específicas para as transferências com o Bizut, bem como a restringir
          a transferência apenas ao estritamente necessário para cumprimento das
          obrigações assumidas com você.
        </p>

        <h2>18. DO USO E COMPARTILHAMENTO DE DADOS PESSOAIS SENSÍVEIS</h2>
        <p>
          No contexto de recrutamento e seleção, o Bizut permite que o candidato
          insira informações e dados pessoais, inclusive considerados sensíveis,
          tais como fotos, vídeos, exames psicológicos, exames psicotécnicos,
          atestados médicos, relatórios sobre necessidades especiais para
          processos seletivos de Pessoas com Deficiência (PCD), informações para
          participação em processos seletivos de vagas inclusivas, dentre
          outros, conforme determinado em lei e previsto no artigo 5º, II da
          LGPD.
        </p>
        <p>
          A inserção dessas informações não é obrigatória e será sempre
          facultativa ao candidato. O seu compartilhamento com as empresas para
          processos de seleção deverá ser feito apenas para participação em
          processos seletivos, mediante autorização do candidato e nunca para
          finalidades discriminatórias.
        </p>
        <p>
          O Bizut se compromete a não utilizar tais informações para outras
          finalidades, bem como envidará seus melhores esforços para garantir
          que seus clientes as utilizem somente dentro das finalidades previstas
          no ordenamento vigente, incluindo a legislação trabalhista. NO
          ENTANTO, A DECISÃO FINAL DE CONTRATAÇÃO CABE À EMPRESA ANUNCIANTE
          EXCLUSIVAMENTE.
        </p>

        <h2>19. PROTEÇÃO DO NOSSO SISTEMA E DOS SEUS DADOS PESSOAIS</h2>
        <p>
          Para proteger os dados pessoais coletados e garantir a segurança dos
          nossos sistemas, utilizamos tecnologias e procedimentos adequados de
          acordo com o nível de risco e o serviço fornecido, possuindo uma
          equipe responsável por gerenciá-los em conformidade com as previsões
          legais, requisitos regulatórios, mudanças de tecnologia, dentre outros
          fatores relevantes que possam influenciar a proteção de dados.
        </p>
        <p>
          Em razão da própria natureza da Internet, existe o risco de que
          terceiros mal-intencionados acessem indevidamente as informações
          armazenadas nos nossos sistemas. Caso isso ocorra, nós nos
          responsabilizamos nos limites previstos na legislação vigente
          aplicável, sempre zelando pela segurança e integridade do sistema,
          fazendo todo o esforço possível, para manter segura as informações no
          sistema armazenadas.
        </p>
        <p>
          É proibido o uso de qualquer dispositivo, software ou outro recurso
          que venha a interferir nas atividades e operações do Bizut, tanto pelo
          site quanto por outros sistemas ou bancos de dados.
        </p>
        <p>
          CASO SEJA IDENTIFICADA QUALQUER INTROMISSÃO, TENTATIVA, OU ATIVIDADE
          QUE VIOLE OU CONTRARIE AS LEIS DE DIREITO DE PROPRIEDADE INTELECTUAL
          E/OU AS DISPOSIÇÕES ESTIPULADAS NESTA POLÍTICA E/OU LEIS VIGENTES
          APLICÁVEIS, AÍ INCLUÍDA A LGPD, O RESPONSÁVEL ESTARÁ SUJEITO ÀS
          SANÇÕES APLICÁVEIS, ESTEJAM ELAS EM LEI OU PREVISTAS NESTE DOCUMENTO.
          O RESPONSÁVEL DEVERÁ, TAMBÉM, INDENIZAR POR EVENTUAIS DANOS CAUSADOS.
        </p>

        <h2>20. POR QUANTO TEMPO UTILIZAMOS OS SEUS DADOS PESSOAIS?</h2>
        <p>
          A intenção do Bizut é acompanhar o profissional durante toda a sua
          carreira. Em nossa base de dados temos informações de jovens
          aprendizes e também de pessoas que possuem tempo para ou já, ou que
          são aposentadas, mas que continuam dispostas a trabalhar.
        </p>
        <p>
          Assim, decidimos acompanhar você por muito tempo e não apenas até que
          consiga uma próxima colocação profissional.
        </p>
        <p>
          CASO PERCEBAMOS QUE VOCÊ NÃO APARECE HÁ MUITOS ANOS E QUE NÃO TEVE
          MOVIMENTAÇÃO, PODEREMOS ADORMECER OU EXCLUIR O SEU CURRÍCULO, MAS ISSO
          NÃO SERÁ FEITO ENQUANTO VOCÊ MANTIVER CONTATO CONOSCO E PERMANECER
          ATIVO.
        </p>
        <p>
          Mas você poderá a qualquer tempo se preferir, solicitar a exclusão da
          sua conta conosco de forma automática, conforme links e procedimentos
          descritos nesta política e acessíveis pelo site www.bizut.com.br.
        </p>

        <h2>21. DIREITOS PREVISTOS PELA LGPD</h2>
        <p>
          Nós garantimos a você o exercício dos direitos previstos na LGPD, a
          saber:
        </p>
        <ol>
          <li>
            Direito de confirmação: você poderá confirmar se o Bizut realiza o
            tratamento de algum dado pessoal seu.
          </li>
          <li>
            Direito de acesso: você poderá conferir detalhes de seus dados, bem
            como obter cópias de referidas informações, através do painel do
            candidato.
          </li>
          <li>
            Direito a correção: você possui acesso integral aos dados do seu
            currículo, podendo alterar, corrigir ou remover eventuais dados
            pessoais que estejam incompletos, inexatos ou desatualizados.
          </li>
          <li>
            Direito de anonimização, bloqueio ou eliminação: você poderá
            solicitar a anonimização, bloqueio ou eliminação de dados
            desnecessários, excessivos ou tratados em desconformidade com as
            finalidades previstas nesta Política, observando-se, para tanto,
            demais normas do ordenamento jurídico. Nesses casos, se houver
            anonimização ou bloqueio de dados que prejudiquem a manutenção ativa
            do seu currículo, sua participação em processos seletivos será
            prejudicada ou impossibilitada.
          </li>
          <li>
            Direito de informação: você poderá solicitar informações sobre com
            quais tipos de empresas compartilhamos os seus dados, bem como ser
            informado das consequências caso opte por não consentir na coleta e
            tratamento de seus dados pessoais, quando necessário.
          </li>
          <li>
            Direito de recusar marketing: a qualquer momento, você poderá
            solicitar o cancelamento de recebimento de materiais pelo próprio
            link disponibilizado no e-mail marketing enviado.
          </li>
          <li>
            Direito de portabilidade de informações: Você poderá exportar todas
            as informações do seu currículo em formato .PDF.
          </li>
          <li>
            Direito de revogação do consentimento: quando a base legal para o
            tratamento de dados for o consentimento, você poderá cancelar esse
            consentimento dado previamente a nós. É importante que você saiba
            que o cancelamento não afetará a utilização ou compartilhamento dos
            dados realizados anteriormente ao pedido de revogação do
            consentimento.
          </li>
          <li>
            Direito de oposição aos tratamentos futuros para as demais bases
            legais. você poderá solicitar a exclusão do seu currículo do Bizut e
            a interrupção de qualquer outro serviço prestado. Mas é importante
            que você saiba que alguns dados poderão ser mantidos para
            finalidades legais e que a exclusão poderá não surtir efeitos para
            as empresas controladoras que obtiveram acesso ao seu currículo
            através do Bizut antes do seu pedido. Vale lembrar que o
            desativamento do currículo impede a sua participação e candidatura a
            novas vagas e isto é irreversível.
          </li>
        </ol>

        <p>
          A qualquer momento você poderá alterar suas configurações ou exercer
          os direitos previstos na LGPD, atravez dos canais de comunicação do
          Bizut.
        </p>
        <p>
          Nós também nos comprometemos a comunicar os demais agentes de
          tratamento com os quais tenhamos compartilhado informações, a fim de
          que também realizem os procedimentos necessários para atender a sua
          solicitação.
        </p>

        <h2>22. DO ENCARREGADO PELO TRATAMENTO DE DADOS PESSOAIS</h2>
        <p>
          O Bizut tem uma equipe de profissionais dedicada à proteção de dados e
          privacidade.
        </p>
        <p>
          Caso tenha alguma questão específica que não tenha sido esclarecida
          por nossa Política de Privacidade ou possa ser encontrada diretamente
          pelo no site www.bizut.com.br, você poderá, como último recurso,
          entrar em contato com o SAC do Bizut e abrir um chamado seguindo os
          procedimentos descritos na página: www.bizut.com.br
        </p>

        <h2>23. COOKIES</h2>
        <p>
          O nosso site utiliza cookies e outras tecnologias semelhantes para
          armazenar e gerenciar as suas preferências de navegação, habilitar
          conteúdos e coletar dados de análise e utilização do site. O uso
          dessas tecnologias é comum em sites e plataformas em geral,
          consistindo em um pequeno arquivo de texto, colocando seu dispositivo
          ou navegador, que permite a sua identificação enquanto usuário e o
          dispositivo utilizado, bem como para coletar as informações de
          navegação.
        </p>
        <p>
          Os cookies que utilizamos podem desempenhar diferentes funções. Alguns
          são necessários e essenciais para a navegação e utilização dos
          recursos da plataforma Bizut em seus sites: www.bizut.com.br e
          www.bizut.me. Outros coletam informações sobre como você utiliza o
          site e servem para melhorar o desempenho e a experiência de navegação.
          Por fim, os cookies funcionais relembram suas escolhas e preferências,
          personalizando a sua experiência no site.
        </p>
        <p>
          Você poderá desabilitar os cookies através das configurações de seu
          navegador, instalando plug-ins disponíveis no mercado, ou ainda
          fazendo uso de outras tecnologias que entenda serem necessárias.
        </p>

        <h2>24. REQUERIMENTOS LEGAIS DE AUTORIDADES COMPETENTES</h2>
        <p>
          Nós temos o compromisso de cooperar com as autoridades competentes e
          terceiros para garantir o cumprimento das leis, inclusive em matéria
          de proteção de direitos de propriedade industrial e intelectual,
          prevenção de fraudes, proteção de dados pessoais, dentre outros. Nesse
          contexto, apenas revelaremos seus dados pessoais mediante
          requerimentos de autoridades judiciais ou governamentais competentes,
          no âmbito de investigações e processos conduzidos por estas, desde que
          não exista vedação legal estabelecendo o sigilo.
        </p>

        <h2>25. DISPOSIÇÕES GERAIS</h2>
        <p>
          O uso do nome Bizut, dos domínio www.bizut.com.br e www.bizut.me, bem
          como os conteúdos das telas do site são nossa propriedade e estão
          protegidos pelas leis e tratados internacionais de propriedade
          intelectual. Você não deverá usar indevidamente ou reproduzir, total
          ou parcialmente, tais conteúdos, salvo quando houver autorização
          expressa.
        </p>
        <p>
          No momento do cadastro, você deverá ler, compreender e aceitar esta
          Política, conforme opção específica disponibilizada no formulário.
          Porém, esta Política tem natureza de contrato de adesão e passa por
          revisões periódicas, sem que seja necessária a sua notificação prévia.
          Por isso, é importante que você consulte o documento para saber se
          continua concordando com seus termos antes de seguir com a navegação.
        </p>
        <p>
          O candidato declara expressamente, por este instrumento, que, nos
          termos do <i>artigo 46</i> do Código de Defesa do Consumidor{' '}
          <b>(Lei nº 8.078/1990)</b>, tomou conhecimento prévio deste contrato
          no site do Bizut tendo inclusive, a possibilidade de imprimi-lo, e que
          avaliou, leu e concorda com todas as disposições e cláusulas aqui
          descritas.
        </p>
        <p>
          O Bizut prestará suporte técnico para utilização plena do site por
          você, nos horários e formas padronizadas pela própria empresa, segundo
          as regras divulgadas no site.
        </p>
        <p>
          Estes Termos de Uso e a Política de Privacidade estão em conformidade
          e deverão ser interpretados com base nas leis vigentes na República
          Federativa do Brasil, bem como como a <b>Lei 13.709/2018</b> (Lei
          Geral de Proteção de Dados)
        </p>
        <p>
          Para dirimir eventuais dúvidas ou questões relativas a eles, as partes
          elegem o Foro Central da Comarca do Rio de Janeiro (RJ), com exclusão
          de qualquer outro.
        </p>
      </div>

      <div className="privacy__footer" ref={footerRef}>
        <div className="checkbox-content">
          <div
            onClick={() => setSelected((prevState) => !prevState)}
            className={`
              checkbox
              ${vibrateBox ? 'checkbox-vibrate' : ''}
              ${isSelected ? 'selected' : 'not-selected'}
            `}
          >
            <span>✔</span>
          </div>
          <span className={vibrateBox ? 'span-vibrate' : ''}>
            Estou ciente e de acordo com os Termos de USO e Políticas de
            Privacidade.
          </span>
        </div>

        <div className="footer-button">
          <button
            type="button"
            onClick={
              isSelected
                ? () => onAcceptTerm(isSelected)
                : () => setVibrateBox(true)
            }
          >
            Eu Aceito
          </button>
        </div>
      </div>
    </div>
  );
}
