import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import AppLocale from 'lang';
import 'helpers/Firebase';
import { getDirection } from 'helpers/Utils';

// import {
//   // adminRoot,
//   // UserRole,
// } from 'constants/defaultValues';

import Support from 'components/Support';
import { NotificationContainer } from 'components/common/react-notifications';

import HashProvider from 'contexts/HashContext';
import StepProvider from 'contexts/StepContext';

import { ResumeRegister, ResumeView, ResumeEdit } from 'views/app/resume';
import Tips from 'views/app/tips';
import Error from 'views/error';

import WrapperValidate from './WrapperValidate';

class App extends React.Component {
  constructor(props) {
    super(props);
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add('rtl');
      document.body.classList.remove('ltr');
    } else {
      document.body.classList.add('ltr');
      document.body.classList.remove('rtl');
    }
  }

  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale];

    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <>
          <NotificationContainer />
          <Support />
          <HashProvider>
            <StepProvider>
              <Router>
                <Switch>
                  <Route exact path="/">
                    <Error />
                  </Route>

                  <Route
                    path="/visualizar/:hash"
                    render={(props) => (
                      <WrapperValidate {...props}>
                        <ResumeView />
                      </WrapperValidate>
                    )}
                  />

                  <Route
                    path="/editar/:hash"
                    render={(props) => (
                      <WrapperValidate {...props}>
                        <ResumeEdit />
                      </WrapperValidate>
                    )}
                  />

                  <Route
                    path="/registrar/:hash"
                    render={(props) => (
                      <WrapperValidate {...props}>
                        <ResumeRegister />
                      </WrapperValidate>
                    )}
                  />

                  <Route path="/tips" component={Tips} />

                  <Route path="*">
                    <Error />
                  </Route>
                </Switch>
              </Router>
            </StepProvider>
          </HashProvider>
        </>
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { currentUser } = authUser;
  const { locale } = settings;
  return { currentUser, locale };
};
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(App);
