/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React from 'react';
import { WithWizard } from 'react-albus';
import Button from 'components/Button';

const BottomNavigation = ({
  className,
  onClickPrev,
  prevLabel,
  onClickNext,
  nextLabel,
  isCompleted,
  isLoading,
}) => {
  return (
    <WithWizard
      render={({ next, previous, step, steps }) => {
        if (isCompleted) {
          next();
        }

        return (
          <div className={`wizard-buttons ${className}`}>
            <Button
              className={`mr-3 ${steps.indexOf(step) <= 0 ? 'disabled' : ''}`}
              label={prevLabel}
              onClick={() => {
                onClickPrev(previous, steps, step);
              }}
            />

            <Button
              isLoading={isLoading}
              type="submit"
              color="primary"
              label={nextLabel}
              onClick={() => {
                onClickNext(next, steps, step);
              }}
            />
          </div>
        );
      }}
    />
  );
};
export default BottomNavigation;
