import React from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';

export default function Modal({ isVisible, children }) {
  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="wrapper-modal">{children}</div>,
    document.getElementById('modal-term')
  );
}
